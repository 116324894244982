import Resource from './resource';
import request from "../utils/request";

class Topics extends Resource {
  constructor() {
    super('topics');
  }

  update(resource,id) {
    return request({
      url: `/topics/update/${id}`,
      method: 'put',
      data: resource

    })
  }
  show_topic(id) {
    return request({
      url: `/topics/show/${id}`,
      method: 'get',

    })
  }

  delete_topic(id) {
    return request({
      url: `/topics/delete_topic/${id}`,
      method: 'delete',

    })
  }
  add(resource) {
    return request({
      url: `/topics/add_topic`,
      method: 'post',
      data: resource
    })
  }
  topics(resource) {
    return request({
      url: `/topics`,
      method: 'post',
      params: resource,

    })
  }
  Import(resource) {
    return request({
      url: `/topics/import`,
      method: 'post',
      'Content-Type': 'multipart/form-data',
      data: resource
    })
  }
  Export(resource) {
    return request({
      url: `/topics/export`,
      method: 'post',
      responseType: 'blob',
      data: resource
    })
  }
  // get user categories
  users_categories(resource) {
    return request({
      url: `/users/categories`,
      method: 'post',
      params: resource,
    })
  }

  add_users_cat_task(payload) {
    if (payload.id != null) {
      return request({
        url: `/users/categories/save/${payload.id}`,
        method: "put",
        data: payload
      });
    } else {
      return request({
        url: `/users/categories/save`,
        method: "put",
        data: payload
      });
    }
  }


  delete_users_cat_task(id) {
    return request({
      url: `/users/categories/delete/${id}`,
      method: 'delete',
    })
  }

  users_categories_details(id) {
    return request({
      url: `/categories/users/${id}`,
      method: 'get',
      // params: payload.query,

    })
  }

  delete_one_user_cat_task(payload) {
    return request({
      url: `/users/categories/delete/user/${payload.id}`,
      method: 'delete',
      data: payload
    })
  }
  delete_all_user_cat_task(id) {
    return request({
      url: `/users/categories/delete/allusers/${id}`,
      method: 'delete',
    })
  }

  categories_tasks(id) {
    return request({
      url: `/categories/tasks/get/${id}`,
      method: 'get',

    })
  }
  // topics/standard
  get_standard_topics() {
    return request({
      url: `/topics/standard`,
      method: 'get',
    })
  }

  //
  add_topics_task(payload) {
    if (payload.id != null) {
      return request({
        url: `/categories/tasks/save/${payload.id}`,
        method: "put",
        data: payload
      });
    } else {
      return request({
        url: `/categories/tasks/save`,
        method: "put",
        data: payload
      });
    }
  }
  // categories/tasks/delete/2
  categories_tasks_delete(id) {
    return request({
      url: `/categories/tasks/delete/${id}`,
      method: 'delete',
    })
  }
}

export {Topics as default};
