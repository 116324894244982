<template>
  <div>
    <router-view></router-view>
    <!-- <p class="xxx-p">hello</p> -->
  </div>
</template>

<script>
import NotificationSample from "src/pages/Dashboard/Components/NotificationSample";
export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("cms/getEveryLocalization", {
      id: null
    });
    // this.$notify(
    //   {
    //     component: NotificationSample,
    //     icon: 'nc-icon nc-app'
    //   })
  }
};
</script>
<style lang="scss">
::selection {
  background-color: #ffa534;
  color: #fff;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #11bfe3;
  border-radius: 10px;
}
// .xxx-p {
//   position: fixed;
//   background: #11bfe3;
//   top: 90px;
//   right: 40px;
// }
</style>
