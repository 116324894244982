import { login, logout, Register } from "../../api/auth"; // getInfo
import User from "../../api/user"; // getInfo
const FileDownload = require("js-file-download");
import {
  Auth,
  deleteToken,
  removeAuth,
  removeToken,
  resetPermission,
  setLogged,
  setToken
} from "../../utils/auth";
import router from "../../routes/index";

const user = new User();

const userModule = {
  namespaced: true,
  state: {
    group_id: null,
    auth: null,
    org: [],
    orgs: [],
    user: {},
    alluserData: {},
    userLoad: false,
    token: null,
    users: [],
    courses: [],
    usersLoad: false,
    questions: [],
    attrs: [],
    isQuestions: false,
    tasks: [],
    onetask: null,
    totalUsers: 0,
    // Categories
    categories: [],
    sequence: null,
    technical_reasoning: null,
    confluence: null,
    precision: null,
    tasks_count: 0,
    task_instructions: null,
    flow_instructions: null,
    join_full_org_error: "",
    topics: null,
    lci_instructions: null,
    // // Teams
    teams: [],
    team_details: {},
    team_members: [],
    invited_team_members: [],
    dashboard_cards: {},
    flow_instruction: null,
    team_cards_content: [],
    team_pages_content: [],
    team_pages_titles_content: [],
    allTeamTasks: [],
    showTeamTasks: [],
    // active tab in edit user page
    what_active: false,
    // Get Team Line Graph And Which Type
    line_graph_users: [],
    line_graph_description_text: "",
    // Get Lci Cards
    lci_cards: {},
    profile_cards: {},
    lci_help_you_cards: {},
    lci_plp_box_cards: {},
    how_to_use_your_patterns: {},
    work_how_to_use_your_patterns: {},
    // videos
    videos: [],
    user_strategy_boxes: [],
    // Start Tasks At Work And Career Match
    atWorkTasks: [],
    atWorkTasksCount: 0,
    atWorkTasksTopics: [],
    careerMatchTasks: [],
    careerMatchTasksCount: 0,
    careerMatchTasksTopics: [],
    showAtWorkTaskDetails: null,
    showCareerMatchTaskDetails: null,
    // family members
    familyMembers: [],
    familyMembersCount: 0,
    familyDetails: {},
    families: [],
    familiesCount: [],
    showFamilyData: [],
    showFamilyDataCount: null,
    showFamilyDataMembers: [],

    //  career statement
    careerStatements: [],
    careerStatementsCount: 0,
    careerStatementsQuestions: [],
    careerStatementsLanguages: [],
    //  career statement questions
    mainCareerStatementsQuestions: [],
    mainCareerStatementsQuestionsCount: 0,
    mainCareerStatementsQuestionsLanguages: [],
    // career Match Statements Questions
    careerMatchStatementsQuestions: {},
    // career Strategies
    careerStrategies: [],
    careerStrategiesCount: 0,
    careerStrategiesLanguages: [],
    careerStrategiesTopics: [],
    showCareerStrategies: {},
    showCareerStrategiesLanguages: [],
    userCareerTasks: [],
    userSchoolTasks: [],
    userNewCareerTasks: [],
    userInstitutionsUsers: []
  },
  getters: {
    usersLoad: state => {
      return state.usersLoad;
    },
    isQuestions: state => {
      return state.isQuestions;
    },
    alluserData: state => {
      return state.alluserData;
    },
    user(state) {
      return Object.entries(state.user)

        .map(([key, value]) => {
          return { key, value };
        })
        .filter(
          param =>
            param.key !== "id" &&
            param.key !== "removed" &&
            param.key !== "type" &&
            param.key !== "about_me" &&
            param.key !== "job_title" &&
            param.key !== "topics" &&
            param.key !== "old_id" &&
            param.key !== "confluence" &&
            param.key !== "precision" &&
            param.key !== "selected_attributes" &&
            param.key !== "sequence" &&
            param.key !== "technical_reasoning" &&
            param.key !== "age_group_id" &&
            param.key !== "skills" &&
            param.key !== "card_name" &&
            param.key !== "card_number" &&
            param.key !== "exp_time" &&
            param.key !== "cvc_code" &&
            param.value !== null &&
            param.value !== "" &&
            param.key !== "isEnabled"
        );
    },
    userLoad(state) {
      return state.userLoad;
    },
    onetask: state => state.onetask,
    tasks: state => state.tasks,
    taskInstructions: state => {
      return state.task_instructions;
    },
    users(state) {
      return state.users;
    },
    totalUsers(state) {
      return state.totalUsers;
    },
    categories(state) {
      return state.categories;
    },
    questions(state) {
      return state.questions;
    },
    orgs(state) {
      return state.org;
    },
    attrs(state) {
      return state.attrs;
    },
    topics(state) {
      return state.topics;
    },
    orgsAll(state) {
      return state.orgs;
    },
    score(state) {
      let score = {
        confluence: state.user.confluence,
        sequence: state.user.sequence,
        technical_reasoning: state.user.technical_reasoning,
        precision: state.user.precision
      };
      return score;
    },
    tasks_count(state) {
      return state.tasks_count;
    },
    join_full_org_error(state) {
      return state.join_full_org_error;
    },
    get_lci_instructions(state) {
      return state.lci_instructions;
    },
    // // Teams
    get_teams(state) {
      return state.teams;
    },
    get_team_details(state) {
      return state.team_details;
    },
    get_team_members(state) {
      return state.team_members;
    },
    get_invited_team_members(state) {
      return state.invited_team_members;
    },
    get_dashboard_cards(state) {
      return state.dashboard_cards;
    },
    user_dashboard_Instructions: state => {
      return state.flow_instructions;
    },
    line_graph_members: state => {
      return state.line_graph_users;
    },
    line_graph_members_description: state => {
      return state.line_graph_description_text;
    },

    get_team_cards_content: state => {
      return state.team_cards_content;
    },
    get_team_pages_content: state => {
      return state.team_pages_content;
    },
    get_team_pages_titles_content: state => {
      return state.team_pages_titles_content;
    },
    getAllTeamTasks: state => {
      return state.allTeamTasks;
    },
    getShowTeamTasks: state => {
      return state.showTeamTasks;
    },

    // Get Lci Cards
    get_lci_cards: state => {
      return state.lci_cards;
    },
    get_lci_help_you_cards: state => {
      return state.lci_help_you_cards;
    },
    get_lci_plp_box_cards: state => {
      return state.lci_plp_box_cards;
    },
    get_profile_cards: state => {
      return state.profile_cards;
    },
    get_how_to_use_your_patterns: state => {
      return state.how_to_use_your_patterns;
    },
    get_work_how_to_use_your_patterns: state => {
      return state.work_how_to_use_your_patterns;
    },
    get_videos: state => {
      return state.videos;
    },
    get_user_strategy_boxes: state => {
      return state.user_strategy_boxes;
    },
    // Start Tasks At Work And Career Match
    getAtWorkTasks: state => {
      return state.atWorkTasks;
    },
    getAtWorkTasksCount: state => {
      return state.atWorkTasksCount;
    },
    getAtWorkTasksTopics: state => {
      return state.atWorkTasksTopics;
    },

    // Career Match
    getCareerMatchTasks: state => {
      return state.careerMatchTasks;
    },
    getCareerMatchTasksCount: state => {
      return state.careerMatchTasksCount;
    },
    getCareerMatchTasksTopics: state => {
      return state.careerMatchTasksTopics;
    },
    getShowAtWorkTaskDetails: state => {
      return state.showAtWorkTaskDetails;
    },
    getShowCareerMatchTaskDetails: state => {
      return state.showCareerMatchTaskDetails;
    },

    // family members
    getFamilyMembers: state => {
      return state.familyMembers;
    },
    getFamilyMembersCount: state => {
      return state.familyMembersCount;
    },
    getFamilyDetails: state => {
      return state.familyDetails;
    },
    // getFamilies
    getFamilies: state => {
      return state.families;
    },
    getFamiliesCount: state => {
      return state.familiesCount;
    },
    getShowFamily: state => {
      return state.showFamilyData;
    },
    getShowFamilyCount: state => {
      return state.showFamilyDataCount;
    },
    getShowFamilyMembers: state => {
      return state.showFamilyDataMembers;
    },
    //  career statement
    getCareerStatements: state => {
      return state.careerStatements;
    },
    getCareerStatementsCount: state => {
      return state.careerStatementsCount;
    },
    getCareerStatementsQuestions: state => {
      return state.careerStatementsQuestions;
    },
    getCareerStatementsLanguages: state => {
      return state.careerStatementsLanguages;
    },
    //  career statement questions
    getMainCareerStatementsQuestions: state => {
      return state.mainCareerStatementsQuestions;
    },
    getMainCareerStatementsQuestionsCount: state => {
      return state.mainCareerStatementsQuestionsCount;
    },
    getMainCareerStatementsQuestionsLanguages: state => {
      return state.mainCareerStatementsQuestionsLanguages;
    },
    // careerMatchStatementsQuestions
    getCareerMatchStatementsQuestions: state => {
      return state.careerMatchStatementsQuestions;
    },

    //  career Strategies
    getCareerStrategies: state => {
      return state.careerStrategies;
    },
    getCareerStrategiesCount: state => {
      return state.careerStrategiesCount;
    },
    getCareerStrategiesTopics: state => {
      return state.careerStrategiesTopics;
    },
    getCareerStrategiesLanguages: state => {
      return state.careerStrategiesLanguages;
    },
    getShowCareerStrategies: state => {
      return state.showCareerStrategies;
    },
    getShowCareerStrategiesLanguages: state => {
      return state.showCareerStrategiesLanguages;
    },
    getUserCareerTasks: state => {
      return state.userCareerTasks;
    },
    getUserNewCareerTasks: state => {
      return state.userNewCareerTasks;
    },
    getUserSchoolTasks: state => {
      return state.userSchoolTasks;
    },
    getInstitutionsUsers: state => {
      return state.userInstitutionsUsers;
    }
  },
  mutations: {
    SET_AUTH: (state, user) => {
      state.auth = user;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USERS: (state, users) => {
      state.users = users;
    },
    //
    SET_CATEGORIES: (state, category) => {
      state.categories = category;
    },

    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_USER_ALL: (state, alluserData) => {
      state.alluserData = alluserData;
    },
    SET_all_QUESTIONS: (state, questions) => {
      if ((questions = [])) {
        state.isQuestions = false;
      } else {
        state.isQuestions = true;
      }
    },
    // SET_all_QUESTIONS: (state, questions) => {
    //   if (questions=[]) {
    //     state.isQuestions =false
    //   }
    //   else{
    //     state.isQuestions =true

    //   }

    // },
    SET_TOPICS: (state, topics) => {
      state.topics = topics;
    },
    SET_ORG: (state, org) => {
      state.org = org;
    },
    SET_ORGS: (state, orgs) => {
      state.orgs = orgs;
    },
    SET_GROUP_ID: (state, group_id) => {
      if (group_id == 1) {
        state.user.age_group = "6-12 years old";
      }
      if (group_id == 2) {
        state.user.age_group = "13 -21 years old";
      }

      if (group_id == 1) {
        state.user.age_group = "22 or older";
      }
    },
    // SET_GROUP_NAME:(state,group_name) =>{
    //   if (group_name== "6-12 years old") {
    //     state.user.age_group_id = 1;
    //   }
    //   if (group_name=="13 -21 years old") {
    //     state.user.age_group_id = 2;
    //   }

    //   if (group_name=="22 or older") {
    //     state.user.age_group_id = 3;
    //   }
    // },
    SET_LOAD_USER: (state, load) => {
      state.userLoad = load;
    },
    SET_TOTAL_USERS: (state, total) => {
      state.totalUsers = total;
    },
    SET_USERS_LOAD: (state, load) => {
      state.usersLoad = load;
    },
    SET_COURSES: (state, courses) => {
      state.courses = courses;
    },
    SET_TASKS: (state, tasks) => {
      state.tasks = tasks;
    },
    SET_QUESTIONS: (state, questions) => {
      state.questions = questions;
    },
    SET_TASK: (state, tasks) => {
      state.tasks = tasks;
    },
    SET_TASK_INSTRUCTIONS: (state, task_inst) => {
      state.task_instructions = task_inst;
    },
    SET_TASK_ONE: (state, onetask) => {
      state.onetask = onetask;
    },
    SET_ATTR: (state, attrs) => {
      state.attrs = attrs;
    },
    SET_TASKS_COUNT: (state, count) => {
      state.tasks_count = count;
    },
    SET_JOIN_FULL_ORG_ERROR: (state, msg) => {
      state.join_full_org_error = msg;
    },
    SET_LCI_INSTRUCTIONS: (state, lci_instructions) => {
      state.lci_instructions = lci_instructions;
    },
    SET_TEAMS: (state, teams) => {
      state.teams = teams;
    },

    SET_TEAMS_CARDS_CONTENT: (state, content) => {
      state.team_cards_content = content;
    },
    SET_TEAMS_PAGES_CONTENT: (state, content) => {
      state.team_pages_content = content;
    },
    SET_TEAMS_PAGES_TITLES_CONTENT: (state, content) => {
      state.team_pages_titles_content = content;
    },
    SET_TEAM_DETAILS: (state, team) => {
      state.team_details = team;
    },
    SET_TEAM_MEMBERS: (state, members) => {
      state.team_members = members;
    },
    SET_TEAM_TASKS: (state, task) => {
      state.allTeamTasks = task;
    },
    SET_SHOW_TEAM_TASKS: (state, task) => {
      state.showTeamTasks = task;
    },
    SET_INVITED_TEAM_MEMBERS: (state, members) => {
      state.invited_team_members = members;
    },
    SET_DASHBOARD_CARDS: (state, cards) => {
      state.dashboard_cards = cards;
    },
    SET_USER_DASHBOARD_INSTRUCTIONS: (state, flow_inst) => {
      state.flow_instructions = flow_inst;
    },
    SET_LINE_GRAPH_USERS: (state, user) => {
      state.line_graph_users = user;
    },
    SET_LINE_GRAPH_DESCRIPTION: (state, TEXT) => {
      state.line_graph_description_text = TEXT;
    },
    SET_LCI_CARDS: (state, info) => {
      state.lci_cards = info;
    },
    SET_LCI_HELP_YOU_CARDS: (state, info) => {
      state.lci_help_you_cards = info;
    },
    SET_LCI_PLP_BOX_CARDS: (state, info) => {
      state.lci_plp_box_cards = info;
    },
    SET_PROFILE_CARDS: (state, info) => {
      state.profile_cards = info;
    },
    SET_How_Use_Patterns: (state, pattern) => {
      state.how_to_use_your_patterns = pattern;
    },
    SET_WORK_How_Use_Patterns: (state, pattern) => {
      state.work_how_to_use_your_patterns = pattern;
    },
    SET_VIDEOS: (state, video) => {
      state.videos = video;
    },
    SET_USER_STRATEGY_BOXES: (state, strategy) => {
      state.user_strategy_boxes = strategy;
    },
    // Start Tasks At Work And Career Match
    SET_AT_WORK_TASKS: (state, task) => {
      state.atWorkTasks = task;
    },
    SET_AT_WORK_TASKS_COUNT: (state, count) => {
      state.atWorkTasksCount = count;
    },
    SET_AT_WORK_TASKS_TOPICS: (state, topic) => {
      state.atWorkTasksTopics = topic;
    },
    SET_CAREER_MATCH_TASKS: (state, task) => {
      state.careerMatchTasks = task;
    },
    SET_CAREER_MATCH_TASKS_COUNT: (state, count) => {
      state.careerMatchTasksCount = count;
    },
    SET_CAREER_MATCH_TASKS_TOPICS: (state, topic) => {
      state.careerMatchTasksTopics = topic;
    },
    SET_AT_WORK_TASKS_Details: (state, details) => {
      state.showAtWorkTaskDetails = details;
    },
    SET_CAREER_MATCH_TASKS_Details: (state, details) => {
      state.showCareerMatchTaskDetails = details;
    },
    // Family Members
    SET_FAMILY_MEMBERS: (state, member) => {
      state.familyMembers = member;
    },
    SET_FAMILY_MEMBERS_COUNT: (state, count) => {
      state.familyMembersCount = count;
    },

    SET_FAMILIES: (state, families) => {
      state.families = families;
    },
    SET_FAMILIES_COUNT: (state, count) => {
      state.familiesCount = count;
    },
    SET_FAMILY_DETAILS: (state, details) => {
      state.familyDetails = details;
    },
    // showFamilyData
    SET_FAMILY_DATA: (state, family) => {
      state.showFamilyData = family;
    },
    SET_FAMILY_DATA_COUNT: (state, count) => {
      state.showFamilyDataCount = count;
    },
    SET_FAMILY_DATA_MEMBERS: (state, member) => {
      state.showFamilyDataMembers = member;
    },
    //  career statement
    SET_CAREER_DATA: (state, item) => {
      state.careerStatements = item;
    },
    SET_CAREER_DATA_COUNT: (state, item) => {
      state.careerStatementsCount = item;
    },
    SET_CAREER_DATA_QUESTIONS: (state, item) => {
      state.careerStatementsQuestions = item;
    },
    SET_CAREER_DATA_LANGUAGES: (state, item) => {
      state.careerStatementsLanguages = item;
    },
    //  career statement questions
    SET_MAIN_CAREER_DATA: (state, item) => {
      state.mainCareerStatementsQuestions = item;
    },
    SET_MAIN_CAREER_DATA_COUNT: (state, item) => {
      state.mainCareerStatementsQuestionsCount = item;
    },
    SET_MAIN_CAREER_DATA_LANGUAGES: (state, item) => {
      state.mainCareerStatementsQuestionsLanguages = item;
    },
    // careerMatchStatementsQuestions
    SET_CAREER_MATCH_STATEMENTS_QUESTIONS: (state, item) => {
      state.careerMatchStatementsQuestions = item;
    },
    //  career Strategies
    SET_CAREER_STRATEGIES: (state, item) => {
      state.careerStrategies = item;
    },
    SET_CAREER_STRATEGIES_COUNT: (state, item) => {
      state.careerStrategiesCount = item;
    },
    SET_CAREER_STRATEGIES_TOPICS: (state, item) => {
      state.careerStrategiesTopics = item;
    },
    SET_CAREER_STRATEGIES_LANGUAGES: (state, item) => {
      state.careerStrategiesLanguages = item;
    },
    SET_SHOW_CAREER_STRATEGIES: (state, item) => {
      state.showCareerStrategies = item;
    },
    SET_SHOW_CAREER_STRATEGIES_LANGUAGES: (state, item) => {
      state.showCareerStrategiesLanguages = item;
    },
    SET_USER_CAREER_TASKS: (state, items) => {
      state.userCareerTasks = items;
    },
    SET_USER_NEW_CAREER_TASKS: (state, items) => {
      state.userNewCareerTasks = items;
    },
    SET_USER_SCHOOL_TASKS: (state, items) => {
      state.userSchoolTasks = items;
    },
    SET_USERS_INSTITUTIONS: (state, items) => {
      state.userInstitutionsUsers = items;
    }
  },
  actions: {
    // user login
    login({ commit, dispatch }, userInfo) {
      const { email, password } = userInfo;
      return new Promise((resolve, reject) => {
        login({ email: email.trim(), password: password })
          .then(response => {
            if (!response.status) {
              setLogged("1");
              setToken(response.access_token);
              Auth(JSON.stringify(response.user));
              commit("SET_TOKEN", response.access_token);
              commit("SET_AUTH", JSON.stringify(response.user));
            }
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    register({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Register(payload.query, payload.contact, payload.team, payload.token)
          .then(response => {
            if (!response.status) {
              setLogged("1");
              setToken(response.access_token);
              Auth(JSON.stringify(response.user));
            }
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    add({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .add(payload.id, payload.status)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    reset({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .reset(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    resetPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .resetPassword(payload.query)
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    changePasswordByAdmin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .changePassword(payload.id, payload.newPasswordData)
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit("SET_TOKEN", "");
            commit("SET_AUTH", []);
            resetPermission();
            removeToken();
            deleteToken();
            removeAuth();
            resolve(resolve);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    users({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .list(payload.query)
          .then(response => {
            commit("SET_USERS", response.users);
            commit("SET_TOTAL_USERS", response.count);
            commit("SET_CATEGORIES", response.categories);
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    get_questions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .get_questions(payload.id)
          .then(response => {
            commit("SET_QUESTIONS", response.questions);
            commit("SET_all_QUESTIONS", response.questions);
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    push_task({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .push_task(payload.query)
          .then(response => {
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //  Start  New Actions
    addCategory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .add_category(payload)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },

    // ? add_custom_attribute
    add_custom_attribute({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .add_custom_attribute(payload)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },

    // ? ocr_photo_description
    get_ocr_photo_description({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .add_ocr_photo_description(payload)
          .then(response => {
            resolve(response);

            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },

    // ? get_text_to_speech
    get_text_to_speech_audio({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // commit("SET_USERS_LOAD", true);
        user
          .get_text_to_speech(payload)
          .then(response => {
            resolve(response);

            // commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            // commit("SET_USERS_LOAD", false);
          });
      });
    },
    //// share profile or tasks
    sharing_profile_task({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // commit("SET_USERS_LOAD", true);
        user
          .share_plp_task(payload)
          .then(response => {
            resolve(response);
            // commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            // commit("SET_USERS_LOAD", false);
          });
      });
    },
    //// delete_custom_attribute
    delete_custom_attribute_profile({ commit }, id) {
      // commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        user
          .delete_custom_attribute(id)
          .then(response => {
            resolve(response);
            // commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            // commit("SET_USERS_LOAD", false);
          });
      });
    },

    //// Start Teams Functionality
    // Add New Team
    add_team({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .add_new_team(payload)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    // Get Team Line Graph Data
    get_team_line_graph({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .get_team_graph_data(payload)
          .then(response => {
            commit("SET_LINE_GRAPH_USERS", response.users);
            commit("SET_LINE_GRAPH_DESCRIPTION", response.team_description);
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    // Get All Teams
    get_all_teams({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .get_teams()
          .then(response => {
            commit("SET_TEAMS", response.teams);
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    // Start Teams Content
    get_all_teams_content({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .get_teams_content()
          .then(response => {
            commit("SET_TEAMS_CARDS_CONTENT", response.cards);

            commit("SET_TEAMS_PAGES_CONTENT", response.cards_instructions);
            commit("SET_TEAMS_PAGES_TITLES_CONTENT", response.cards_titles);

            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },

    // Edit Team
    edit_team({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .edit_teams(payload)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },

    // Delete Team
    delete_teams({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .delete_team(payload.teamId, payload.authId)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // Delete Team
    delete_team_from_teams({ commit }, id) {
      return new Promise((resolve, reject) => {
        user
          .delete_team_from_teams(id)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // Invite One To Team
    invite_one_to_team({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .invite_to_team(payload)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    // Get All Teams
    every_team_members({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .get_every_team_members(id)
          .then(response => {
            commit("SET_TEAM_DETAILS", response.team);
            commit("SET_TEAM_MEMBERS", response.team_users);
            commit("SET_INVITED_TEAM_MEMBERS", response.invited_members);
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    // Delete Member From Team
    delete_member_from_team({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        user
          .delete_team_member(payload.team_id, payload.member_id)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    // Delete Invited Member From Team
    delete_invited_member_from_team({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        user
          .delete_invited_team_member(payload)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    // Delete Invited Member From Team
    add_team_statement_for_member({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        user
          .save_team_patterns(payload)

          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },

    // Handler add statement for member in working together page
    add_team_statement_for_member_working_together({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        user
          .save_team_statement_working(
            payload.user_id,
            payload.team_id,
            payload
          )
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    // Handler add statement for member in working together page
    handlerTeamTasks({ commit }, payload) {
      console.log(payload);
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        user
          .team_tasks(payload.id, payload.query)
          .then(response => {
            commit("SET_TEAM_TASKS", response.task);
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    handlerShowTeamTasks({ commit }, id) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        user
          .show_team_tasks(id)
          .then(response => {
            commit("SET_SHOW_TEAM_TASKS", response.task);
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },

    // team_tasks

    //// End  Teams Functionality

    // Get Dashboard Cards Status
    get_dashboard_card_status({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .get_dashboard_status()
          .then(response => {
            commit("SET_DASHBOARD_CARDS", response);
            // console.log(response);
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },

    // End New Actions

    show_task({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .show_task(payload.id)
          .then(response => {
            console.log("response show task", response);
            commit("SET_TASK_ONE", response.task);
            commit("SET_TOPICS", response.topics);
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    add_task({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .add_task(payload.query)
          .then(response => {
            router.push(`/edit-task/${response.task.id}`);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    edit_task({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .edit_task(payload.query, payload.id)
          .then(response => {
            // router.push(`/edit-task/${response.task.id}`);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    Import({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .Import(payload.query)
          .then(response => {
            console.log(response);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    export({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .export(payload.query)
          .then(response => {
            FileDownload(response, "users.xlsx");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    save_answers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // commit('SET_USERS_LOAD', true);
        user
          .save_answers(payload.query)
          .then(response => {
            // router.push("/profile");

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    leaveOrganization({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .leaveOrganization(payload.id)
          .then(response => {
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    joinOrganization({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .joinOrganization(payload.promoCode, payload.id)
          .then(response => {
            // call SET_JOIN_FULL_ORG_ERROR only if there is an error message back from server
            if (response.error_message) {
              commit("SET_JOIN_FULL_ORG_ERROR", response.error_message);
            } else {
              commit("SET_JOIN_FULL_ORG_ERROR", "");
            }
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    user({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .get(id)
          .then(response => {
            commit("SET_USER", response.user);
            commit("SET_USER_ALL", response.user);
            commit("SET_ORG", response.organization);
            commit("SET_TASK", response.tasks);
            commit("SET_ATTR", response.attributes);

            commit("SET_TASKS_COUNT", response.tasks_count);
            commit("SET_GROUP_ID", response.user.age_group_id);
            // flow_instructions
            commit("SET_TASK_INSTRUCTIONS", response.task_instructions); // this line for getting profile instructions from backend
            commit(
              "SET_USER_DASHBOARD_INSTRUCTIONS",
              response.flow_instructions
            ); // this line for getting profile instructions from backend

            commit("SET_TASKS", response.tasks);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    userTask({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .user_task(payload.id, payload.query)
          .then(response => {
            console.log("user task response", response);
            commit("SET_USER", response.user);
            commit("SET_USER_ALL", response.user);
            commit("SET_TASKS_COUNT", response.tasks_count);
            commit("SET_TASK_INSTRUCTIONS", response.task_instructions);
            commit(
              "SET_USER_DASHBOARD_INSTRUCTIONS",
              response.flow_instructions
            );
            commit("SET_TASKS", response.tasks);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },

    orgs({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .orgs()
          .then(response => {
            commit("SET_USER", response.user);
            commit("SET_ORGS", response.organizations);

            commit("SET_LOAD_USER", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    update_task({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .update_task(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    update_attribute({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .update_attribute(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    save({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .store(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // add user payment
    processPayment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .renew(payload.query, payload.id)
          .then(response => {
            console.log(response);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    saveQuestionnaire({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .store(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        user
          .delete(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //// Remove User From Institutions
    deleteUserFromInstitutions({ commit }, id) {
      return new Promise((resolve, reject) => {
        user
          .delete_user_institution(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    delete_task({ commit }, id) {
      return new Promise((resolve, reject) => {
        user
          .deleteTask(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    forgotPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .forgotPassword(payload.forgotEmail)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    resetNewPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .resetNewPassword(payload.newPasswordData)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    syncClassRoom({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .sync_class(payload.id, payload.email)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    unsyncClassRoom({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .unsync_class(payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getLciInstructions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .get_lci_instructions()
          .then(response => {
            commit("SET_LCI_INSTRUCTIONS", response.lci_instructions);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // Lci And Profile Cards
    handlerProfileCards({ commit }) {
      return new Promise((resolve, reject) => {
        user
          .get_profile_cards()
          .then(response => {
            commit("SET_PROFILE_CARDS", response.lci_cards);

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    handlerLciCards({ commit }) {
      return new Promise((resolve, reject) => {
        user
          .get_lci_cards()
          .then(response => {
            commit("SET_LCI_CARDS", response.lci_cards);

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    handlerLciHelpYouCards({ commit }) {
      return new Promise((resolve, reject) => {
        user
          .get_lci_help_you_cards()
          .then(response => {
            commit("SET_LCI_HELP_YOU_CARDS", response.how_lci_help_you_cms);

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    ////
    handlerLciPlpBoxCards({ commit }) {
      return new Promise((resolve, reject) => {
        user
          .get_lci_plp_box_cards()
          .then(response => {
            commit("SET_LCI_PLP_BOX_CARDS", response.plp_box);
            console.log(response);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    handlerHowToUseYourPatterns({ commit }) {
      return new Promise((resolve, reject) => {
        user
          .get_how_to_use_pattern()
          .then(response => {
            commit("SET_How_Use_Patterns", response.how_to_use_your_pattern);

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    handlerWorkHowToUseYourPatterns({ commit }) {
      return new Promise((resolve, reject) => {
        user
          .get_work_how_to_use_pattern()
          .then(response => {
            commit(
              "SET_WORK_How_Use_Patterns",
              response.work_how_to_use_your_pattern
            );

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    handlerGetVideos({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .get_video()
          .then(response => {
            commit("SET_VIDEOS", response.video);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handlerUserStrategyBoxes({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .get_user_strategy_boxes()
          .then(response => {
            commit("SET_USER_STRATEGY_BOXES", response.strategy_directions_box);

            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    deleteTeamTaskTalkingProject({ commit }, id) {
      return new Promise((resolve, reject) => {
        user
          .deleteTeamTask(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    addNewTaskTalkingProject({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .add_new_team_task(payload.id, payload.query)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    editNewTaskTalkingProject({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .edit_new_team_task(payload.id, payload.query)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },

    // exportStatements
    handlerExportTeamStatements({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .exportStatements(payload.query)
          .then(response => {
            FileDownload(response, "teamStatements.xlsx");
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },

    // Start Tasks At Work And Career Match
    // Start Tasks At Work
    // All Tasks
    handlerWorkTasks({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .work_tasks(payload.id, payload.query)
          .then(response => {
            commit("SET_AT_WORK_TASKS", response.task);
            commit("SET_AT_WORK_TASKS_COUNT", response.count);

            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // All Topics
    handlerWorkTopics({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .get_at_work_topics()
          .then(response => {
            commit("SET_AT_WORK_TASKS_TOPICS", response.topics);
            resolve(response);
            commit("SET_LOAD_USER", false);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Add At Work Task
    handleAddWorkTask({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .add_at_work_task(payload.query)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Edit At Work Task
    handleEditWorkTask({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .edit_at_work_task(payload.query, payload.id)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Delete At Work Task
    handleDeleteWorkTask({ commit }, id) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .delete_at_work_task(id)
          .then(response => {
            resolve(response);
            commit("SET_LOAD_USER", false);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Show At Work Details
    showAtWorkTask({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .show_at_work_task(payload.id)
          .then(response => {
            commit("SET_AT_WORK_TASKS_Details", response.task);
            commit("SET_AT_WORK_TASKS_TOPICS", response.topics);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Update At Work Task
    updateAtWorkTask({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .update_at_work_task(payload.query, payload.id)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    //// Start Tasks Career Match
    handlerCareerMatchTasks({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .career_match_tasks(payload.id, payload.query)
          .then(response => {
            commit("SET_CAREER_MATCH_TASKS", response.task);
            commit("SET_CAREER_MATCH_TASKS_COUNT", response.count);

            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // All Topics
    handlerCareerMatchTopics({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .get_career_match_topics()
          .then(response => {
            commit("SET_CAREER_MATCH_TASKS_TOPICS", response.topics);

            resolve(response);
            commit("SET_LOAD_USER", false);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Add Career Match Task
    handleAddrCareerMatchTask({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .add_career_match_task(payload.query)
          .then(response => {
            commit("SET_LOAD_USER", false);
            router.push(`/edit-career-match-task/${response.task.id}`);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Edit Career Match Task
    handleEditCareerMatchTask({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .edit_career_match_task(payload.query, payload.id)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Delete Career Match Task
    handleDeleteCareerMatchTask({ commit }, id) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .delete_career_match_task(id)
          .then(response => {
            resolve(response);
            commit("SET_LOAD_USER", false);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Show Career Match Details
    showCareerMatchTask({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .show_career_match_task(payload.id)
          .then(response => {
            commit("SET_CAREER_MATCH_TASKS_Details", response.task);
            commit("SET_CAREER_MATCH_TASKS_TOPICS", response.topics);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Update Career Match Task
    updateCareerMatchTask({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .update_career_match_task(payload.query, payload.id)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },

    // Family Members
    handlerFamilyMembers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .get_family_members(payload.query)
          .then(response => {
            commit("SET_FAMILY_MEMBERS", response.family_members);
            commit("SET_FAMILY_MEMBERS_COUNT", response.count);
            commit("SET_FAMILY_DETAILS", response.family_details);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },

    // Families
    handlerFamilies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .get_families(payload.query)
          .then(response => {
            commit("SET_FAMILIES", response.families);
            commit("SET_FAMILIES_COUNT", response.count);

            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    updateFamilies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .update_families(payload.query, payload.id)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Show Career Match Details
    showFamily({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .show_family(payload.id)
          .then(response => {
            commit("SET_FAMILY_DATA", response.family);
            commit("SET_FAMILY_DATA_MEMBERS", response.members);
            commit("SET_FAMILY_DATA_COUNT", response.count);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },

    // career statement  in management page
    handlerCareerStatements({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .get_career_statements(payload.query)
          .then(response => {
            commit("SET_CAREER_DATA", response.statements);
            commit("SET_CAREER_DATA_COUNT", response.count);
            commit("SET_CAREER_DATA_QUESTIONS", response.questions);
            commit("SET_CAREER_DATA_LANGUAGES", response.languages);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handleDeleteCareerStatements({ commit }, id) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .delete_career_statements(id)
          .then(response => {
            resolve(response);
            commit("SET_LOAD_USER", false);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handleAddrCareerStatements({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .add_career_statements(payload.query)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handleUpdateCareerStatements({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .update_career_statements(payload.query, payload.id)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // career statement Questions  in management page
    handlerCareerStatementsQuestions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .get_career_statements_questions(payload.query)
          .then(response => {
            commit("SET_MAIN_CAREER_DATA", response.questions);
            commit("SET_MAIN_CAREER_DATA_COUNT", response.count);
            commit("SET_MAIN_CAREER_DATA_LANGUAGES", response.languages);

            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handleDeleteCareerStatementsQuestions({ commit }, id) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .delete_career_statements_questions(id)
          .then(response => {
            resolve(response);
            commit("SET_LOAD_USER", false);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handleAddrCareerStatementsQuestions({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .add_career_statements_questions(payload.query)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handleUpdateCareerStatementsQuestions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .update_career_statements_questions(payload.query, payload.id)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    //   career match   questions
    handlerCareerMatchStatementsQuestions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .career_match_questions(payload.query)
          .then(response => {
            commit(
              "SET_CAREER_MATCH_STATEMENTS_QUESTIONS",
              response.my_statements
            );

            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // export Career Statements
    handlerExportCareerStatements({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .exportCareerStatements(payload.query)
          .then(response => {
            FileDownload(response, "careerStatements.xlsx");
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },
    // career Strategies  in management page
    handlerCareerStrategies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .get_career_strategies(payload.query)
          .then(response => {
            commit("SET_CAREER_STRATEGIES", response.starategies);
            commit("SET_CAREER_STRATEGIES_COUNT", response.count);
            commit("SET_CAREER_STRATEGIES_TOPICS", response.career_topics);
            commit("SET_CAREER_STRATEGIES_LANGUAGES", response.languages);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handleDeleteCareerStrategies({ commit }, id) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .delete_career_strategies(id)
          .then(response => {
            resolve(response);
            commit("SET_LOAD_USER", false);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handleAddrCareerStrategies({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .add_career_strategies(payload.query)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handleUpdateCareerStrategies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .update_career_strategies(payload.query, payload.id)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    showStrategy({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD_USER", true);
        user
          .show_strategies(payload.id)
          .then(response => {
            commit("SET_SHOW_CAREER_STRATEGIES", response.strategy);
            commit("SET_SHOW_CAREER_STRATEGIES_LANGUAGES", response.languages);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // export Career Strategies
    handlerExportCareerStrategies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .exportCareerStrategies(payload.query)
          .then(response => {
            FileDownload(response, "careerStrategies.xlsx");
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },

    handlerUserCareerMatchBoxes({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .get_user_career_match_boxes()
          .then(response => {
            // console.log(response);
            // commit("SET_USER_STRATEGY_BOXES", response.strategy_directions_box
            // );

            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // in career match -- tab questions and next page career match
    handlerUserCareerMatchPageOne({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .get_career_match_statement()
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handlerUserCareerMatchPageTwo({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .get_career_match_statement_two()
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handlerPushCareerTask({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        user
          .push_career_task(payload.query)
          .then(response => {
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // Start CMS For User Dashboard Or Family And LCI
    // 1 - User Dashboard CMS
    handlerUserDashboardCms({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .get_user_dashboard_cms()
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // 1 - User Dashboard Family CMS
    handlerUserDashboardFamilyCms({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .get_user_family_dashboard_cms()
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // 1 - User Lci CMS
    handlerUserLciCms({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .get_user_lci_cms()
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },

    handleAddNewFamilyMember({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .add_new_family_member(payload.query)
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handlePrintAtWorkTasks({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      console.log(payload);
      return new Promise((resolve, reject) => {
        user
          .printAtWorkTasks(payload)
          .then(response => {
            console.log(response);
            commit("SET_USER_CAREER_TASKS", response.tasks);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handlePrintSchoolTasks({ commit }, payload) {
      commit("SET_LOAD_USER", true);
      console.log(payload);
      return new Promise((resolve, reject) => {
        user
          .printSchoolTasks(payload)
          .then(response => {
            console.log(response);
            commit("SET_USER_SCHOOL_TASKS", response.tasks);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handlePrintCareerTasks({ commit }, payload) {
      commit("SET_LOAD_USER", true);

      return new Promise((resolve, reject) => {
        user
          .printCareerTasks(payload)
          .then(response => {
            console.log(response);
            commit("SET_USER_NEW_CAREER_TASKS", response.tasks);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    handlePrintInstitutionsUsers({ commit }, payload) {
      commit("SET_LOAD_USER", true);

      return new Promise((resolve, reject) => {
        user
          .printInstitutionsUsers(payload)
          .then(response => {
            console.log(response);
            commit("SET_USERS_INSTITUTIONS", response.users_data);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // dashboard cards
    handlerUserDashboardCmsCards({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .my_dashboard_cards()
          .then(response => {
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // dashboard cards
    handlerUserNewDashboardCmsCards({ commit }) {
      commit("SET_LOAD_USER", true);
      return new Promise((resolve, reject) => {
        user
          .my_dashboard_new_cms_cards()
          .then(response => {
            console.log(response);
            commit("SET_LOAD_USER", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOAD_USER", false);
            reject(error);
          });
      });
    },
    // Share all tasks
    sharing_all_tasks({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // commit("SET_USERS_LOAD", true);
        const formData = new FormData();
        formData.append("email", payload.email);
        user
          .share_all_task(payload.id, formData)
          .then(response => {
            resolve(response);
            // commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            // commit("SET_USERS_LOAD", false);
          });
      });
    }
  }
};

export default userModule;
