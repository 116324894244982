<template>
  <div class="container-login100">
    <div class="wrap-login100 shadow-lg p-4">
      <el-row style="width: 100%">
        <el-col>
          <fade-render-transition
            :duration="500"
            class="pt-3 pb-5"
            style="width: 100%"
          >
            <!-- Login Form  -->
            <el-form
              v-model="login"
              :model="login"
              style="width: 95%"
              v-if="!forgot"
              @submit.prevent="submit"
            >
              <!-- Start Dropdown Language  -->
              <div class="drop-language">
                <el-select
                  class="select-default"
                  v-model="selectedLanguageId"
                  :placeholder="`${publicLocalization('Language')}`"
                  @change="switchLocalization()"
                >
                  <el-option
                    v-for="item in publishedLanguages"
                    :key="item.id"
                    :value="item.id"
                    :label="item.title"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- End Dropdown Language  -->

              <!-- Start Logo  -->
              <div class="mb-2">
                <h2 class="card-title text-center mb-3">
                  {{ publicLocalization("Login") }}
                </h2>
                <!-- <div>
                  {{ $store.getters["cms/getShowpublicLocalizationRegisterPage"] }}
                </div> -->
                <img
                  src="../../../assets/images/logo/03.png"
                  class="logos d-block m-auto"
                  alt="IMG"
                />
              </div>
              <!-- End Logo  -->
              <div class="login_what_patterns">
                <div class="text_patterns_box">
                  <span class="pat_text">{{
                    publicLocalization("what are your patterns?")
                  }}</span>
                  <span class="pat_one"></span>
                  <span class="pat_two"></span>
                  <span class="pat_three"></span>
                  <span class="pat_four"></span>
                  <span class="pat_five"></span>
                  <span class="pat_six"></span>
                  <span class="pat_seven"></span>
                  <span class="pat_eight"></span>
                </div>
              </div>

              <!-- Start Login Text  -->
              <div
                class="mb-4  mt-5 wrap-of-text"
                :class="{ is_arabic: isRtl == true }"
              >
                <span class="txt1">
                  <i class="fa fa-bell ring-icon mr-1"></i>
                  {{ publicLocalization("Login text") }}
                </span>
                <a class="txt2" @click="forgot = true">
                  {{ publicLocalization("Reset your password") }}
                </a>
              </div>
              <!-- End Login Text  -->

              <!-- Enter Mail Input  -->
              <div>
                <el-form-item
                  :label="`${publicLocalization('Email')}`"
                  prop="email"
                  :rules="[
                    {
                      required: true,
                      message: `${$t('validation.Enter your email')}`,
                      trigger: 'blur'
                    },
                    {
                      type: 'email',
                      message: `${$t('validation.Enter your email')}`,
                      trigger: ['blur', 'change']
                    }
                  ]"
                  class="every-item"
                  :class="{ is_arabic: isRtl == true }"
                >
                  <el-input
                    type="email"
                    v-model="login.email"
                    :placeholder="`${publicLocalization('Enter your email')}`"
                    style="width: 100%"
                    clear="inputs-weap"
                    class="just_pass_inarabic"
                  ></el-input>
                </el-form-item>
              </div>

              <!-- Enter Password Input -->
              <div>
                <el-form-item
                  :label="`${publicLocalization('password')}`"
                  prop="password"
                  :rules="[
                    {
                      required: true,
                      message: 'Enter your password',
                      trigger: 'blur'
                    }
                  ]"
                  class="every-item position-relative"
                  :class="{ is_arabic: isRtl == true }"
                >
                  <i
                    v-if="passwordType == 'password'"
                    class="
                      fa fa-eye
                      position-absolute
                      togglePassword
                      text-secondary
                    "
                    @click="toggleShowPassword"
                    aria-hidden="true"
                    v-tooltip.top-center="
                      `${publicLocalization('Show Password')}`
                    "
                  ></i>
                  <i
                    v-if="passwordType == 'text'"
                    class="
                      fa fa-eye-slash
                      position-absolute
                      togglePassword
                      text-secondary
                      curoser
                    "
                    v-tooltip.top-center="
                      `${publicLocalization('Hide Password')}`
                    "
                    @click="toggleShowPassword"
                    aria-hidden="true"
                  ></i>
                  <el-input
                    :type="passwordType"
                    v-model="login.password"
                    :placeholder="
                      `${publicLocalization('Enter your password')}`
                    "
                    style="width: 100%"
                    clear="inputs-weap"
                    class="just_pass_inarabic"
                  ></el-input>
                </el-form-item>
              </div>

              <div class=" Forgot-password mb-2">
                <a class="txt" href="#" @click="forgot = true">
                  {{ publicLocalization("Forgot your password") }}
                </a>
              </div>
              <div style="width: 100%" class="mb-3 mt-3">
                <el-button
                  @click="submit"
                  type=""
                  class="pl-5 pr-5 lgin-btn m-auto d-block submit_lci_buttons"
                >
                  {{ publicLocalization("Login") }}
                </el-button>
              </div>
              <!-- Forgot password button  -->

              <!-- Create new account button  -->
              <div
                class="text-center new-account pb-3 mt-4"
                :class="{ is_arabic: isRtl == true }"
              >
                {{ publicLocalization("Don’t have an account") }}
                <a
                  :class="{ is_arabic: isRtl == true }"
                  class="txt2"
                  style="cursor: pointer"
                  @click="$router.push('/register')"
                  >{{ publicLocalization("Sign Up") }}
                  <i
                    v-if="isRtl == false"
                    class="fa fa-long-arrow-right m-l-5"
                    aria-hidden="true"
                  ></i>
                  <i
                    v-if="isRtl == true"
                    class="fa fa-long-arrow-left m-l-5"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </el-form>
            <!-- End Login Form  -->

            <!-- Form Reset password email  -->
            <el-form
              v-model="reset"
              :model="reset"
              :rules="resetRules"
              @submit.prevent="sendResetMail"
              style="width: 95%"
              ref="resetRef"
              v-else
            >
              <div class="mb-5 for-backing">
                <i
                  @click="forgot = false"
                  class="el-icon-back goo-back shadow-sm "
                ></i>
                <h2 class="card-title text-center mb-3">
                  {{ publicLocalization("Forgot Password") }}
                </h2>
              </div>
              <div class="main-forgetpass">
                <el-form-item
                  :label="`${publicLocalization('Email')}`"
                  prop="email"
                  label-width="forget-pass-label"
                  class="mb-5"
                  style="width: 100%"
                  :class="{ is_arabic: isRtl == true }"
                >
                  <el-input
                    type="email"
                    v-model="reset.email"
                    @keyup.enter.native="sendResetMail"
                    :placeholder="`${publicLocalization('Enter your email')}`"
                    style="width: 100%"
                    class="just_pass_inarabic"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="flex-column flex-lg-row d-md-flex my-3 text-center">
                <!-- <el-button
                  @click="forgot = false"
                  type="info"
                  class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
                >
                  Back
                </el-button> -->
                <el-button
                  @click="sendResetMail"
                  type=""
                  class="
                  btn-forgetpass
                    text-center
                    px-4 px-lg-3
                    mx-auto
                    d-block
                    my-1
                  "
                >
                  {{ publicLocalization("Send Email") }}
                </el-button>
              </div>
            </el-form>
          </fade-render-transition>
        </el-col>
      </el-row>
      <!-- <div>{{ $store.getters["cms/getShowpublicLocalizationRegisterPage"] }}</div> -->
    </div>
  </div>
</template>

<script>
import { FadeRenderTransition } from "src/components/index";
import Swal from "sweetalert2";
// 4mha2XNAn7FNNjNXX9x8
export default {
  name: "login",
  data() {
    return {
      isloginForm: false,
      forgot: false,
      passwordType: "password",
      login: {},
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date();
        }
      },
      reset: {},
      resetRules: {
        email: [
          {
            required: true,
            message: "Enter your email",
            trigger: "blur"
          }
        ]
      },
      isArabic: false,
      selectedLanguageId: null,
      isRtl: false // Language Direction
    };
  },
  mounted() {
    // window.addEventListener("keypress", (e) => {
    //   if (e.key == "Enter") {
    //     if (!this.forgot) {
    //       this.submit();
    //     } else {
    //       this.sendResetMail();
    //     }
    //   }
    // });

    // Get Published Language
    this.$store.dispatch("language/getLanguages");
    this.$store.dispatch("cms/getLocalizationForRegister", {
      id: null
    });
    // this.$store.dispatch("cms/getEverypublicLocalization", {
    //   id: null
    // });
  },
  components: {
    FadeRenderTransition
  },
  computed: {
    ar_lang() {
      return this.isArabic;
    },
    languageDirection() {
      return this.isRtl;
    },
    publishedLanguages() {
      return this.$store.getters["language/languages"];
    }
  },
  methods: {
    toggleShowPassword() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    submit() {
      if (this.login.email && this.login.password) {
        this.$store.dispatch("user/login", this.login).then(res => {
          let userData = res.user;
          if (
            userData.confluence != null &&
            userData.precision != null &&
            userData.sequence != null &&
            userData.technical_reasoning != null
          ) {
            this.$router.push("/my-dashboard");
          } else {
            this.$router.push("/");
          }
          console.log(userData);
        });
      }
    },
    sendResetMail() {
      this.$refs["resetRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/forgotPassword", {
              forgotEmail: {
                email: this.reset.email
              }
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                title: `${this.publicLocalization(
                  "Forgot Password Confirmation"
                )}`,
                showConfirmButton: false,
                showCloseButton: true,
                timer: 4000
              });
            });
        } else {
          console.log("error submitting");
          return false;
        }
      });
    },
    switchs(local) {
      this.$i18n.locale = local;
      // if (this.$i18n.locale == "ar") {
      //   this.isArabic = true;
      // } else {
      //   this.isArabic = false;
      // }
    },
    switchLocalization() {
      this.$store
        .dispatch("cms/getEveryLocalizationForRegisterPages", {
          language: this.selectedLanguageId
        })
        .then(() => {
          this.publishedLanguages.filter(item => {
            if (item.id == this.selectedLanguageId) {
              console.log(item.rtl);
              console.log(item);
              if (item.rtl == 1) {
                this.isRtl = true;
              } else {
                this.isRtl = false;
              }
            }
          });
          // console.log(this.publishedLanguages);
          // this.selectedLanguageId
        });
    }
  }
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600&display=swap");
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: url("/static/img/full-screen-image-4.jpg") no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  &.is_arabic {
    font-family: "Cairo", sans-serif;
  }
}

.wrap-login100 {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 50px 20px 50px;
  padding: 0 50px 20px 50px;
  &.is_arabic {
    font-family: "Cairo", sans-serif;
  }
  .card-title {
    margin-top: 0 !important;
  }
  .wrap-of-text {
    font-size: 16px;
    color: #909399;
    text-align: center;
    &.is_arabic {
      direction: rtl;
      color: red !important;
      font-family: "Cairo", sans-serif;
    }
  }
  .every-item {
    width: 60%;
    margin: 20px auto;
    @media (min-width: 320px) and (max-width: 567px) {
      width: 100%;
    }
  }
}
::v-deep .el-input__inner {
  padding: 22px 7px !important;
  transition: 0.3s all ease-in-out;
  background-color: #fff !important;
  border: 1px solid #dee0e6;
  &:focus,
  .is-active,
  &:hover {
    background-color: #fff !important;
    border-color: #23ccef;
  }
}
// ? asterisks
::v-deep {
  .el-form-item {
    .el-form-item__label {
      width: 100%;
      text-align: left;
      // &::before {
      //   content: "" !important;
      // }
    }
    &.is_arabic {
      .el-form-item__label {
        text-align: right;
        font-family: "Cairo", sans-serif;
      }
      .el-form-item__content {
        .just_pass_inarabic {
          direction: rtl;
        }
      }
      .togglePassword {
        left: 12px !important;
        right: auto !important;
      }
    }
  }
}
.main-forgetpass {
  width: 60%;
  margin: 15px auto 0;
  @media (min-width: 320px) and (max-width: 567px) {
    width: 100%;
  }
}
.Forgot-password {
  width: 60%;
  margin: 15px auto 0;
  text-align: right;
  @media (min-width: 320px) and (max-width: 567px) {
    width: 100%;
  }
  .txt {
    color: #e63b45 !important;
    font-size: 14px;
    transition: 0.3s all ease-in-out;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
.new-account {
  color: #909399;
  &.is_arabic {
    direction: rtl;
    font-family: "Cairo", sans-serif;
  }
  .text2 {
    color: #23ccef;
    font-size: 14px;
    transition: 0.3s all ease-in-out;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
.txt2 {
  color: #23ccef;
  font-size: 14px;
  transition: 0.3s all ease-in-out;
  opacity: 0.7;
  &.is_arabic {
    font-family: "Cairo", sans-serif !important;
  }
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.lgin-btn {
  transition: 0.3s all ease-in-out;
  opacity: 0.7;
  background-color: #23ccef;
  border: none;
  outline: none;
  color: #ffff;
  cursor: pointer;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    opacity: 1;
  }
}
.submit_lci_buttons {
  background: #00563f;
}
.togglePassword {
  z-index: 5;
  right: 12px;
  top: 75%;
  transform: translateY(-50%);
  font-size: 1.1rem;
  cursor: pointer;
}
.ring-icon {
  color: #e63b45;
}
// .logos {
//   width: 300px;
//   height: 220px;
// }

.for-backing {
  position: relative;
  .goo-back {
    background-color: #00563f;

    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }
}
.btn-forgetpass {
  background-color: #23ccef;
  border-color: #23ccef;
  color: #fff;
}
.drop-language {
  display: flex;
  flex-direction: row-reverse;
}
.drow-down {
  width: 100px !important;
  text-align: center;
  .el-dropdown-menu__item {
    padding: 0;

    span {
      width: 100%;
      display: block;
      transition: 0.3s all ease-in-out;
      &:hover {
        background-color: #23ccef;
        color: #fff;
      }
    }
  }
}
::v-deep .select-default.el-select .el-input:hover input {
  background-color: #888888 !important;
}
// .submit_lci_buttons {
//   background: #00563f;
// }
// .ttete {
//   background-color: #3c3;
//   background-color: #f93;
//   display: inline;
//   background-color: #39c;
//   background-color: #c36;
// }
$width: 25%;
.login_what_patterns {
  .text_patterns_box {
    width: 80%;
    margin: auto;
    // background: aqua;
    text-align: center;
    font-size: 2.7rem;
    font-weight: bold;
    text-transform: uppercase;
    border: 5px solid transparent;
    // border-left-color: #39c;
    // border-right-color: #c36;
    color: #777;
    position: relative;
    @media (min-width: 700px) and (max-width: 991px) {
      width: 100%;
      font-size: 2.3rem;
    }
    @media (min-width: 460px) and (max-width: 700px) {
      width: 100%;
      font-size: 1.5rem;
    }
    @media (min-width: 320px) and (max-width: 460px) {
      width: 100%;
      font-size: 0.9rem;
    }
    // pat_text
    &::before {
      position: absolute;
      content: "";
      left: 0;
      height: 100%;
      width: 5px;
      background-color: #39c;
    }
    &::after {
      position: absolute;
      content: "";
      right: 0;
      height: 100%;
      width: 5px;
      background-color: #c36;
    }

    .pat_one {
      position: absolute;
      top: -5px;
      left: 0;
      height: 5px;
      width: $width;
      background-color: #39c;
    }

    .pat_two {
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 5px;
      width: $width;
      background-color: #39c;
    }
    .pat_three {
      position: absolute;
      top: -5px;
      left: $width;
      height: 5px;
      width: $width;
      background-color: #3c3;
    }
    .pat_four {
      position: absolute;
      bottom: -5px;
      left: $width;
      height: 5px;
      width: $width;
      background-color: #3c3;
    }

    .pat_five {
      position: absolute;
      top: -5px;
      left: $width * 2;
      height: 5px;
      width: $width;
      background-color: #f93;
    }
    .pat_six {
      position: absolute;
      bottom: -5px;
      left: $width * 2;
      height: 5px;
      width: $width;
      background-color: #f93;
    }

    .pat_seven {
      position: absolute;
      top: -5px;
      left: $width * 3;
      height: 5px;
      width: $width;
      background-color: #c36;
    }
    .pat_eight {
      position: absolute;
      bottom: -5px;
      left: $width * 3;
      height: 5px;
      width: $width;
      background-color: #c36;
    }
  }
}
</style>
