import Resource from "../api/resource";
import request from "../utils/request";

class User extends Resource {
  constructor() {
    super("users");
  }

  renew(data, id) {
    return request({
      url: `/users/renew/${id}`,
      method: "put",
      data
    });
  }

  get_questions(id) {
    return request({
      url: `/users/get_questions/${id}`,
      method: "post"
    });
  }
  user_task(id, query) {
    return request({
      url: `/users/show/${id}`,
      params: query,
      method: "get"
    });
  }
  save_answers(resource) {
    return request({
      url: `/users/save_answers`,
      method: "post",
      data: resource
    });
  }
  show_task(id) {
    return request({
      url: `/tasks/show/${id}`,
      method: "get"
    });
  }

  update_task(resource, id) {
    return request({
      url: `/tasks/update/${id}`,
      method: "put",
      data: resource
    });
  }
  get_contacts() {
    return request({
      url: `/users/contacts`,
      method: "post"
    });
  }

  Import(resource) {
    return request({
      url: `/users/import`,
      method: "post",
      "Content-Type": "multipart/form-data",
      data: resource
    });
  }

  export(query) {
    return request({
      url: `/users/export`,
      method: "post",
      responseType: "blob",
      params: query
    });
  }

  add_task(resource) {
    return request({
      url: `tasks/add_task`,
      method: "post",
      data: resource
    });
  }

  edit_task(resource, id) {
    return request({
      url: `tasks/edit/${id}`,
      method: "put",
      data: resource
    });
  }
  orgs() {
    return request({
      url: `/orgs`,
      method: "post"
    });
  }
  deleteTask(id) {
    return request({
      url: `tasks/delete/${id}`,
      method: "delete"
    });
  }

  push_task(resource) {
    return request({
      url: `/tasks/push_task`,
      method: "post",
      data: resource
    });
  }

  update_attribute(resource, id) {
    return request({
      url: `users/update_my_attributes/${id}`,
      method: "put",
      data: resource
    });
  }

  leaveOrganization(id) {
    return request({
      url: `/users/leave_organization/${id}`,
      method: "put"
    });
  }

  joinOrganization(promo, id) {
    return request({
      url: `/users/join_organization/${id}`,
      method: "put",
      params: promo
    });
  }

  forgotPassword(userMail) {
    // console.log(userMail);
    return request({
      url: `/users/forgot/password`,
      method: "post",
      params: userMail
    });
  }

  resetNewPassword(newPasswordData) {
    return request({
      url: `/user/reset/password`,
      method: "post",
      params: newPasswordData
    });
  }
  // change password by admin
  changePassword(id, newPasswordData) {
    // console.log(id, newPasswordData);
    return request({
      url: `/users/update/password/${id}`,
      method: "put",
      data: newPasswordData
    });
  }

  sync_class(id, mail) {
    return request({
      url: `/users/google/classroom/${id}`,
      method: "put",
      data: mail
    });
  }

  unsync_class(id) {
    return request({
      url: `/users/google/classroom/unsync/${id}`,
      method: "put"
    });
  }

  get_lci_instructions() {
    return request({
      url: `/users/lci/instructions`,
      method: "post"
    });
  }

  add_category(payload) {
    return request({
      url: `/users/add/category`,
      method: "post",
      data: payload
    });
  }
  add_custom_attribute(payload) {
    return request({
      url: `/attributes/add/custom/attribute`,
      method: "post",
      data: payload
    });
  }
  add_ocr_photo_description(payload) {
    return request({
      url: `/tasks/get/description`,
      method: "post",
      data: payload
    });
  }
  //? text to speech
  get_text_to_speech(payload) {
    return request({
      url: `/users/text/to/speech`,
      method: "post",
      data: payload,
      responseType: "blob"
    });
  }
  delete_custom_attribute(id) {
    return request({
      url: `/attributes/delete/custom/attribute/${id}`,
      method: "delete"
    });
  }
  share_plp_task(payload) {
    return request({
      url: `/users/share`,
      method: "post",
      data: payload
    });
  }
  share_all_task(id, email) {
    return request({
      url: `/tasks/share/${id}`,
      method: "post",
      data: email
    });
  }
  //// Start Teams Functionality
  add_new_team(payload) {
    return request({
      url: `/teams/add/team`,
      method: "post",
      data: payload
    });
  }

  get_team_graph_data(payload) {
    return request({
      url: `/teams/build/team`,
      method: "post",
      data: payload
    });
  }
  get_teams() {
    return request({
      url: `/users/teams`,
      method: "post"
    });
  }
  // Get Teams Content for pages and cards
  get_teams_content() {
    return request({
      url: `/teams/cards`,
      method: "post"
    });
  }
  edit_teams(payload) {
    return request({
      url: `/teams/update/${payload.id}`,
      method: "put",
      data: payload
    });
  }
  delete_team(teamId, authId) {
    return request({
      url: `/users/remove/team/member/${teamId}/${authId}`,
      method: "delete"
    });
  }
  delete_team_from_teams(id) {
    return request({
      url: `/teams/delete/${id}`,
      method: "delete"
    });
  }
  invite_to_team(payload) {
    return request({
      url: `users/invite/team/member/${payload.id}`,
      method: "post",
      data: payload
    });
  }
  get_every_team_members(id) {
    return request({
      url: `/teams/myteams/members/${id}`,
      method: "post"
    });
  }
  delete_team_member(team_id, member_id) {
    return request({
      url: `users/remove/team/member/${team_id}/${member_id}`,
      method: "delete"
    });
  }
  delete_invited_team_member(payload) {
    return request({
      url: `users/remove/invited/member/${payload.team_id}`,
      method: "delete",
      data: payload
    });
  }
  save_team_patterns(payload) {
    if (payload.id != null) {
      return request({
        url: `users/save/team/pattern/statements/${payload.id}`,
        method: "post",
        data: payload
      });
    } else {
      return request({
        url: `users/save/team/pattern/statements`,
        method: "post",
        data: payload
      });
    }
  }
  save_team_statement_working(user_id, team_id, payload) {
    return request({
      url: `users/save/team/pattern/statements/${user_id}/${team_id}`,
      method: "post",
      data: payload
    });
  }
  // team_tasks
  team_tasks(id, query) {
    if (id != null) {
      return request({
        url: `teams/tasks/${id}`,
        method: "post",
        params: query
      });
    } else {
      return request({
        url: `teams/tasks`,
        method: "post",
        params: query
      });
    }
  }
  show_team_tasks(id) {
    return request({
      url: `tasks/show/team/task/${id}`,
      method: "get"
    });
  }

  add_new_team_task(team_id, payload) {
    return request({
      url: `/teams/add/task/${team_id}`,
      method: "post",
      data: payload
    });
  }
  edit_new_team_task(team_id, payload) {
    return request({
      url: `/tasks/team/edit/${team_id}`,
      method: "put",
      data: payload
    });
  }
  //// End  Teams Functionality

  // Dashboard Status
  get_dashboard_status() {
    return request({
      url: `/users/dashboard`,
      method: "post"
    });
  }
  //// Remove User From Institutions
  delete_user_institution(id) {
    return request({
      url: `/users/leave_organization/${id}`,
      method: "put"
    });
  }
  // Profile lci cards
  get_profile_cards() {
    return request({
      url: `/users/get/profile/cards`,
      method: "get"
    });
  }
  get_lci_cards() {
    return request({
      url: `/users/get/lci/cards`,
      method: "get"
    });
  }
  get_lci_help_you_cards() {
    return request({
      url: `/users/how/lci/help/you`,
      method: "get"
    });
  }
  // profile last box in bottom
  get_lci_plp_box_cards() {
    return request({
      url: `/users/plp/box`,
      method: "get"
    });
  }
  get_how_to_use_pattern() {
    return request({
      url: `/users/how/to/use/your/pattern`,
      method: "get"
    });
  }
  get_work_how_to_use_pattern() {
    return request({
      url: `/users/work/how/to/use/your/pattern`,
      method: "get"
    });
  }
  get_video() {
    return request({
      url: `/users/video`,
      method: "get"
    });
  }
  get_user_strategy_boxes() {
    return request({
      url: `/users/strategy/directions/box`,
      method: "get"
    });
  }

  // talking project
  deleteTeamTask(id) {
    return request({
      url: `tasks/delete/team/task/${id}`,
      method: "delete"
    });
  }

  exportStatements(query) {
    return request({
      url: `/statements/export`,
      method: "post",
      responseType: "blob",
      params: query
    });
  }

  // Start Tasks At Work And Career Match
  // At Work
  work_tasks(id, query) {
    return request({
      url: `/work/tasks/${id}`,
      params: query,
      method: "post"
    });
  }
  get_at_work_topics() {
    return request({
      url: `/topics/work/tasks`,
      method: "get"
    });
  }
  add_at_work_task(resource) {
    return request({
      url: `tasks/add/work/task`,
      method: "post",
      data: resource
    });
  }
  edit_at_work_task(resource, id) {
    return request({
      url: `tasks/edit/work/task/${id}`,
      method: "put",
      data: resource
    });
  }
  delete_at_work_task(id) {
    return request({
      url: `tasks/delete/work/task/${id}`,
      method: "delete"
    });
  }
  show_at_work_task(id) {
    return request({
      url: `/tasks/show/work/task/${id}`,
      method: "get"
    });
  }
  update_at_work_task(resource, id) {
    return request({
      url: `/tasks/update/work/task/${id}`,
      method: "put",
      data: resource
    });
  }
  // Career Match
  career_match_tasks(id, query) {
    return request({
      url: `/career/tasks/${id}`,
      params: query,
      method: "post"
    });
  }
  get_career_match_topics() {
    return request({
      url: `/topics/career/tasks`,
      method: "get"
    });
  }
  add_career_match_task(resource) {
    return request({
      url: `tasks/add/career/task`,
      method: "post",
      data: resource
    });
  }
  edit_career_match_task(resource, id) {
    return request({
      url: `tasks/edit/career/task/${id}`,
      method: "put",
      data: resource
    });
  }
  delete_career_match_task(id) {
    return request({
      url: `tasks/delete/career/task/${id}`,
      method: "delete"
    });
  }
  show_career_match_task(id) {
    return request({
      url: `/tasks/show/career/task/${id}`,
      method: "get"
    });
  }
  update_career_match_task(resource, id) {
    return request({
      url: `/tasks/update/career/task/${id}`,
      method: "put",
      data: resource
    });
  }
  // family members
  get_family_members(query) {
    return request({
      url: `/users/family/members`,
      params: query,
      method: "post"
    });
  }
  // families
  get_families(query) {
    return request({
      url: `/families`,
      params: query,
      method: "post"
    });
  }
  update_families(resource, id) {
    return request({
      url: `/families/update/${id}`,
      method: "put",
      data: resource
    });
  }
  show_family(id) {
    return request({
      url: `/families/show/${id}`,
      method: "get"
    });
  }
  //  career statement
  get_career_statements(query) {
    return request({
      url: `/career/statements`,
      params: query,
      method: "post"
    });
  }
  delete_career_statements(id) {
    return request({
      url: `career/statements/delete/statement/${id}`,
      method: "delete"
    });
  }
  add_career_statements(resource) {
    return request({
      url: `career/statements/add/statement`,
      method: "post",
      data: resource
    });
  }
  update_career_statements(resource, id) {
    return request({
      url: `/career/statements/edit/statement/${id}`,
      method: "put",
      data: resource
    });
  }
  //  career statement questions
  get_career_statements_questions(query) {
    return request({
      url: `/career/statements/questions`,
      params: query,
      method: "post"
    });
  }
  add_career_statements_questions(resource) {
    return request({
      url: `career/statements/questions/add/statement`,
      method: "post",
      data: resource
    });
  }
  update_career_statements_questions(resource, id) {
    return request({
      url: `/career/statements/questions/edit/${id}`,
      method: "put",
      data: resource
    });
  }
  delete_career_statements_questions(id) {
    return request({
      url: `career/statements/questions/delete/${id}`,
      method: "delete"
    });
  }
  //  career match   questions
  career_match_questions(query) {
    return request({
      url: `/users/career/statements`,
      params: query,
      method: "post"
    });
  }
  // export Career Statements
  exportCareerStatements(query) {
    return request({
      url: `/career/statements/export`,
      method: "post",
      responseType: "blob",
      params: query
    });
  }

  //  career strategies
  get_career_strategies(query) {
    return request({
      url: `/career/strategies`,
      params: query,
      method: "post"
    });
  }
  delete_career_strategies(id) {
    return request({
      url: `career/strategies/delete/${id}`,
      method: "delete"
    });
  }
  add_career_strategies(resource) {
    return request({
      url: `career/strategies/add_strategy`,
      method: "post",
      data: resource
    });
  }
  update_career_strategies(resource, id) {
    return request({
      url: `/career/strategies/update/${id}`,
      method: "put",
      data: resource
    });
  }
  show_strategies(id) {
    return request({
      url: `/career/strategies/show/${id}`,
      method: "get"
    });
  }

  // export Career Statements
  exportCareerStrategies(query) {
    return request({
      url: `/career/strategies/export`,
      method: "get",
      responseType: "blob",
      params: query
    });
  }

  get_user_career_match_boxes() {
    return request({
      url: `/users/career/match`,
      method: "get"
    });
  }
  get_career_match_statement() {
    return request({
      url: `/users/career/match/statement`,
      method: "get"
    });
  }
  get_career_match_statement_two() {
    return request({
      url: `/users/career/match/tasks`,
      method: "get"
    });
  }

  push_career_task(resource) {
    return request({
      url: `/tasks/push/career/task`,
      method: "post",
      data: resource
    });
  }

  get_user_dashboard_cms() {
    return request({
      url: `/users/dashboard/cms`,
      method: "get"
    });
  }
  get_user_family_dashboard_cms() {
    return request({
      url: `/users/family/dashboard/cms`,
      method: "get"
    });
  }
  get_user_lci_cms() {
    return request({
      url: `/users/lci/cms`,
      method: "get"
    });
  }

  add_new_family_member(resource) {
    return request({
      url: `users/add/family/member`,
      method: "post",
      data: resource
    });
  }

  printAtWorkTasks(payload) {
    return request({
      url: `/tasks/show/work/task`,
      method: "get",
      params: payload
    });
  }
  printSchoolTasks(payload) {
    return request({
      url: `/tasks/show`,
      method: "get",
      params: payload
    });
  }
  printCareerTasks(payload) {
    return request({
      url: `/tasks/show/career/task`,
      method: "get",
      params: payload
    });
  }

  my_dashboard_cards(resource) {
    return request({
      url: `users/dashboard`,
      method: "post",
      data: resource
    });
  }

  my_dashboard_new_cms_cards() {
    return request({
      url: `/users/dashboard/cards`,
      method: "get"
    });
  }

  printInstitutionsUsers(payload) {
    return request({
      url: `/users/print/plp`,
      method: "post",
      data: payload
    });
  }
}

export { User as default };
