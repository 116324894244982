<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid" style="flex-wrap:nowrap">
      <!-- Navbar Minimize Button  -->
      <div class="navbar-minimize">
        <button
          class="btn get-llink btn-outline btn-fill btn-round btn-icon d-none d-lg-block"
          @click="minimizeSidebar"
        >
          <i
            :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'"
          ></i>
        </button>
      </div>
      <i
        v-if="$route.meta === true"
        class="fa fa-arrow-left ml-2 mr-4"
        style="font-size: 20px; cursor: pointer"
        @click="$router.go(-1)"
      ></i>
      <div class="main_wrapper_for_user_details">
        <a
          class="navbar-brand "
          :class="{
            'd-none':
              $route.name == 'Introduce Yourself' ||
              $route.name == 'Building The Team' ||
              $route.name == 'Working Together' ||
              $route.name == 'Tackling Project'
          }"
        >
          {{
            localization(`${$route.name}`) != false
              ? localization(`${$route.name}`)
              : $route.name
          }}
        </a>
        <!-- Introduce Yourself  Page Route -->
        <a class="navbar-brand " v-if="$route.name == 'Introduce Yourself'">
          {{
            $route.name == "Introduce Yourself" &&
            localization("Card One Title") != false
              ? localization("Card One Title")
              : $route.name
          }}
        </a>
        <!-- Building Team  Page Route -->
        <a class="navbar-brand " v-if="$route.name == 'Building The Team'">
          {{
            $route.name == "Building The Team" &&
            localization("Card Two Title") != false
              ? localization("Card Two Title")
              : $route.name
          }}
        </a>
        <!-- Working Together  Page Route -->
        <a class="navbar-brand " v-if="$route.name == 'Working Together'">
          {{
            $route.name == "Working Together" &&
            localization("Card Three Title") != false
              ? localization("Card Three Title")
              : $route.name
          }}
        </a>
        <!-- Talking Project  Page Route -->
        <a class="navbar-brand " v-if="$route.name == 'Tackling Project'">
          {{
            $route.name == "Tackling Project" &&
            localization("Card Four Title") != false
              ? localization("Card Four Title")
              : $route.name
          }}
        </a>

        <div class="user_name_and_img_wrapper">
          <router-link class="router_link_container" to="/profile">
            <img
              v-if="image != null"
              class="user_image d-block"
              :src="image"
              alt="user_image"
            />
            <i
              v-if="image == null"
              class="fa fa-user user_image d-flex align-items-center justify-content-center"
            ></i>
            <span class="user_name d-block"
              >{{ auth().first_name }} {{ auth().last_name }}</span
            >
          </router-link>
        </div>
      </div>
      <button
        type="button"
        class="navbar-toggler navbar-toggler-right"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-expanded="false"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <!--        <ul class="nav navbar-nav mr-auto">-->
        <!--          <form class="navbar-form navbar-left navbar-search-form" role="search">-->
        <!--            <div class="input-group">-->
        <!--              <label for="searchInput"></label>-->
        <!--              <i class="nc-icon nc-zoom-split"></i>-->
        <!--              <input id="searchInput" type="text" value="" class="form-control" placeholder="Search...">-->
        <!--            </div>-->
        <!--          </form>-->
        <!--        </ul>-->
        <!--        <ul class="navbar-nav">-->
        <!--          <drop-down>-->
        <!--            <i slot="title" class="nc-icon nc-planet"></i>-->
        <!--            <a class="dropdown-item" href="#">Create New Post</a>-->
        <!--            <a class="dropdown-item" href="#">Manage Something</a>-->
        <!--            <a class="dropdown-item" href="#">Do Nothing</a>-->
        <!--            <a class="dropdown-item" href="#">Submit to live</a>-->
        <!--            <li class="divider"></li>-->
        <!--            <a class="dropdown-item" href="#">Another action</a>-->

        <!--          </drop-down>-->
        <!--          <drop-down>-->
        <!--            <template slot="title">-->
        <!--              <i class="nc-icon nc-bell-55"></i>-->
        <!--              <span class="notification">5</span>-->
        <!--              <span class="d-lg-none">Notification</span>-->
        <!--            </template>-->
        <!--            <a class="dropdown-item" href="#">Notification 1</a>-->
        <!--            <a class="dropdown-item" href="#">Notification 2</a>-->
        <!--            <a class="dropdown-item" href="#">Notification 3</a>-->
        <!--            <a class="dropdown-item" href="#">Notification 4</a>-->
        <!--            <a class="dropdown-item" href="#">Another notification</a>-->
        <!--          </drop-down>-->
        <!--          <drop-down position="right">-->
        <!--            <i slot="title" class="nc-icon nc-bullet-list-67"></i>-->

        <!--            <a class="dropdown-item" href="#">-->
        <!--              <i class="nc-icon nc-email-85"></i> Messages-->
        <!--            </a>-->
        <!--            <a class="dropdown-item" href="#">-->
        <!--              <i class="nc-icon nc-umbrella-13"></i> Help Center-->
        <!--            </a>-->
        <!--            <a class="dropdown-item" href="#">-->
        <!--              <i class="nc-icon nc-settings-90"></i> Settings-->
        <!--            </a>-->
        <!--            <div class="divider"></div>-->
        <!--            <a class="dropdown-item" href="#">-->
        <!--              <i class="nc-icon nc-lock-circle-open"></i> Lock Screen-->
        <!--            </a>-->
        <!--            <a href="#" class="dropdown-item text-danger">-->
        <!--              <i class="nc-icon nc-button-power"></i> Log out-->
        <!--            </a>-->

        <!--          </drop-down>-->
        <!--        </ul>-->
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      activeNotifications: false
    };
  },

  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    image() {
      if (this.auth().image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${
          this.auth().image
        }`;
      }
      // return "/static/img/lml-swerl.jpg";
    }
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    }
  }
};
</script>
<style scoped lang="scss">
.navbar-minimize {
  .get-llink {
    // background-color: #23ccef;
    background-color: transparent !important;
    border: none;
    i {
      color: #23ccef;
    }
  }
}
i:hover {
  color: #23ccef;
}
.main_wrapper_for_user_details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: 767px) and (max-width: 991px) {
    width: 92%;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    width: 80%;
  }
  .user_name_and_img_wrapper {
    @media (min-width: 320px) and (max-width: 480px) {
      text-align: center;
      font-size: 13px;
    }
    .router_link_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      transition: 0.3s;
      flex-wrap: wrap;
      @media (min-width: 320px) and (max-width: 480px) {
        justify-content: center;
      }
      .user_image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #ccc;
        padding: 2px;
        background: #fff;
        transition: 0.3s;
      }
      .user_name {
        text-transform: capitalize;
        color: #777;
        transition: 0.3s;
      }
      &:hover {
        .user_name {
          color: #23ccef;
          transform: translateX(4px);
        }
        .user_image {
          transform: rotate(360deg);
        }
      }
    }
  }
}
</style>
