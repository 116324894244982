
import Attributes from "../../api/attributes"; // getInfo
const FileDownload = require("js-file-download");
const attributes = new Attributes();

const attributesModule = {
  namespaced: true,
  state: {
    usersLoad: false, // loading while fetching data
    attributesList: [], // all attributes
    // attribute: {}, // single attribute
    attributesCount: null, // total number of attributes
    languages: [],
    age_groups: [],
    //// Team Statements
    team_statements:[],
    every_team_statements_type:{},
    team_languages:[],
    team_count:null,
    user_statements:[],
    questionStatementsFilter:[],
    // Start Statements Questions
    statementsQuestions:[],
    statementsQuestionsLanguages:[],
    statementsQuestionsCount:null,
    /// End  Statements Questions

    // Start Task Categories
    allTaskCategories:[],
    categoriesCount:null,
    categoryDetailsTasks:[],
    categoryDetailsTopics:[],
    categoryDetailsCount:null,
    categoryForTaskName:{}
    // End Task Categories
  },
  getters: {
    // return all attributes list
    get_attributes: state => {
      return state.attributesList;
    },
    // return single attribute
    // attribute: state => {
    //   return state.attribute;
    // },
    // return number of attributes
    attributesCount: state => {
      return state.attributesCount;
    },
    // return loading while data fetching state
    usersLoad: state => {
      return state.usersLoad;
    },
    languages: state => {
      // console.log(state);
      return state.languages;
    },
    age_groups: state => {
      return state.age_groups;
    },
    //// Start Team Statements
    get_team_statements: state => {
      return state.team_statements;
    },
    get_every_team_statements_type: state => {
      return state.every_team_statements_type;
    },
    get_team_statements_count: state => {
      return state.team_count;
    },
    get_user_statements: state => {
      return state.user_statements;
    },
    getQuestionStatementsFilter: state => {
      return state.questionStatementsFilter;
    },
    //// End Team Statements

    // Start  Statements Questions
    getStatementsQuestions: state => {
      return state.statementsQuestions;
    },
    getStatementsQuestionsLanguages: state => {
      return state.statementsQuestionsLanguages;
    },
    getStatementsQuestionsCount: state => {
      return state.statementsQuestionsCount;
    },
    /// End  Statements Questions

    // Start Task Categories
    getTaskCategories: state => {
      return state.allTaskCategories;
    },
    getTaskCategoriesCount: state => {
      return state.categoriesCount;
    },
    getCategoryDetailsTasks: state => {
      return state.categoryDetailsTasks;
    },
    getCategoryDetailsTopics: state => {
      return state.categoryDetailsTopics;
    },
    getCategoryDetailsCount: state => {
      return state.categoryDetailsCount;
    },
    getCategoryForTaskName: state => {
      return state.categoryForTaskName;
    },



    // End Task Categories

  },

  mutations: {
    SET_ATTRIBUTES: (state, attributes) => {
      state.attributesList = attributes;
    },
    // SET_ATTRIBUTE: (state, attribute) => {
    //   state.attribute = attribute;
    // },
    SET_ATTRIBUTES_COUNT: (state, attributesCount) => {
      state.attributesCount = attributesCount;
    },
    SET_USERS_LOAD: (state, load) => {
      state.usersLoad = load;
    },
    SET_LANGUAGES: (state, languages) => {
      state.languages = languages;
    },
    SET_AGE_GROUPS: (state, age_groups) => {
      state.age_groups = age_groups;
    },
    //// Team Statements
    SET_TEAM_STATEMENTS: (state, statement) => {
      state.team_statements = statement;
    },
    SET_EVERY_TEAM_STATEMENT_TYPE: (state, state_type) => {
      state.every_team_statements_type = state_type;
    },
    SET_STATEMENTS_COUNT: (state, state_count) => {
      state.team_count = state_count;
    },
    SET_USER_STATEMENTS: (state, statement) => {
      state.user_statements = statement;
    },
    SET_QUESTIONS_STATEMENTS_FILTER: (state, question) => {
      state.questionStatementsFilter = question;
    },
    // Start StatementsQuestions
    SET_QUESTIONS_STATEMENTS: (state, questions) => {
      state.statementsQuestions = questions;
    },
    SET_QUESTIONS_STATEMENTS_LANGUAGES: (state, questions_languages) => {
      state.statementsQuestionsLanguages = questions_languages;
    },
    SET_QUESTIONS_STATEMENTS_COUNT: (state, questions_numbers) => {
      state.statementsQuestionsCount = questions_numbers;
    },
    // End StatementsQuestions

    // Start Task Categories
    SET_TASK_CATEGORIES: (state, category) => {
      state.allTaskCategories = category;
    },
    SET_TASK_CATEGORIES_COUNT: (state, categoryCount) => {
      state.categoriesCount = categoryCount;
    },
    SET_CATEGORY_DETAILS_TASKS: (state, task) => {
      state.categoryDetailsTasks = task;
    },
    SET_CATEGORY_DETAILS_TOPICS: (state, topic) => {
      state.categoryDetailsTopics = topic;
    },
    SET_CATEGORY_DETAILS_COUNT: (state, taskCount) => {
      state.categoryDetailsCount = taskCount;
    },
    SET_CATEGORY_FOR_TASK_NAME: (state, taskName) => {
      state.categoryForTaskName = taskName;
    },




    // End Task Categories
  },
  actions: {
    // attribute({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     commit("SET_USERS_LOAD", true);
    //     attributes
    //       .show_topic(payload.id)
    //       .then(response => {
    //         commit("SET_ATTRIBUTE", response.topic);
    //         commit("SET_LANG", response.languages);
    //         commit("SET_USERS_LOAD", false);
    //         resolve();
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },

    EditAttribute({ commit }, payload) {
      return new Promise((resolve, reject) => {
        attributes
          .edit_attribute(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    GetAttributes({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .get_attributes(payload.query)
          .then(response => {
            commit("SET_ATTRIBUTES", response.attributes);
            commit("SET_ATTRIBUTES_COUNT", response.count);
            commit("SET_LANGUAGES", response.languages); // for language table column
            commit("SET_AGE_GROUPS", response.age_group); // for age group table column
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });

      });
    },
    AddAttribute({ commit }, payload) {
      return new Promise((resolve, reject) => {
        attributes
          .add_attribute(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    DeleteAttribute({ commit }, id) {
      return new Promise((resolve, reject) => {
        attributes
          .delete_attribute(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    Import({ commit }, payload) {
      return new Promise((resolve, reject) => {
        attributes
          .Import(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    Export({ commit }, payload) {
      return new Promise((resolve, reject) => {
        attributes
          .Export(payload.query)
          .then(response => {
            FileDownload(response, "attributes.xlsx");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // Bulk process
    ImportBulk({ commit }, payload) {
      return new Promise((resolve, reject) => {
        attributes
          .Import_bulk(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    ExportBulk({ commit }) {
      return new Promise((resolve, reject) => {
        attributes
          .Export_bulk()
          .then(response => {
            FileDownload(response, "Temp_attributes.xlsx");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    //// Team Statements
    // Get All Teams Statement
    GetTeamStatements({ commit },payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .get_team_statements(payload.query)
          .then(response => {
            commit("SET_TEAM_STATEMENTS",response.statements);
            commit("SET_STATEMENTS_COUNT", response.count);
            commit("SET_LANGUAGES", response.languages);
            commit("SET_EVERY_TEAM_STATEMENT_TYPE", response.statements);
            commit("SET_QUESTIONS_STATEMENTS_FILTER", response.questions);
            commit("SET_USERS_LOAD", false);
            resolve(response)
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // Add Team Statement
    AddTeamStatement({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .add_team_statement(payload.query)
          .then(response => {
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // Edit Team Statement
    EditTeamStatement({ commit }, payload) {
      return new Promise((resolve, reject) => {
        attributes
          .edit_team_statement(payload.query, payload.id)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // Delete Team Statement
    DeleteTeamStatement({ commit }, id) {
      return new Promise((resolve, reject) => {
        attributes
          .delete_team_statement(id)
          .then(response => {
            console.log(id);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getUserStatements({ commit },payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .get_user_statements(payload.query)
          .then(response => {
            commit("SET_USER_STATEMENTS",response.my_statements);
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },
    //// End Statements

    // Start Statements Questions
    // Get All Statements Questions
    GetStatementsQuestions({ commit },payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .get_statements_questions(payload.query)
          .then(response => {
            commit("SET_QUESTIONS_STATEMENTS",response.questions);
            commit("SET_QUESTIONS_STATEMENTS_COUNT", response.count);
            commit("SET_QUESTIONS_STATEMENTS_LANGUAGES", response.languages);
            console.log(response);
            resolve(resolve)
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // Add  Statement Questions
    AddStatementQuestion({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .add_statement_questions(payload.query)
          .then(response => {
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // Edit Team Statement
    EditStatementQuestion({ commit }, payload) {
      return new Promise((resolve, reject) => {
        attributes
          .edit_statement_questions(payload.query, payload.id)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    DeleteStatementQuestion({ commit }, id) {
      return new Promise((resolve, reject) => {
        attributes
          .delete_statement_questions(id)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // Statements For Introducing Yourself
    updateIntroducingStatements({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .update_introducing_statements(payload.query, payload.id)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },

    // Start Task Categories

    // Get All Task Categories
    GetAllTaskCategories({ commit },payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .get_task_categories(payload.query)
          .then(response => {
            commit("SET_TASK_CATEGORIES",response.categories);

            commit("SET_TASK_CATEGORIES_COUNT", response.count);
            commit("SET_USERS_LOAD", false);
            resolve(resolve)
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },
    // Add New Category
    addNewCategory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .add_new_category(payload)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },
    // Delete Category
    DeleteCategory({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .delete_category(id)
          .then(response => {
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },
    // Show Category Details
    everyCategoryDetails({ commit },payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .show_category_details(payload.query,payload.id)
          .then(response => {
            commit("SET_CATEGORY_DETAILS_TASKS", response.tasks);
            commit("SET_CATEGORY_DETAILS_TOPICS", response.topics);
            commit("SET_CATEGORY_DETAILS_COUNT", response.count);
            commit("SET_CATEGORY_FOR_TASK_NAME", response.category);
            console.log(response.topics);
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    // Add New Task
    addNewTask({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .add_new_task(payload)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    }, // Delete Category
    deleteTask({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        attributes
          .delete_task(id)
          .then(response => {
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },

 // updateCareerMatchStatements
 updateCareerMatchStatements({ commit }, payload) {
  return new Promise((resolve, reject) => {
    commit("SET_USERS_LOAD", true);
    attributes
      .update_career_match_statements(payload.query, payload.id)
      .then(response => {
        resolve(response);
        commit("SET_USERS_LOAD", false);
      })
      .catch(error => {
        reject(error);
        commit("SET_USERS_LOAD", false);
      });
  });
},


    // End Task Categories
  }
};

export default attributesModule;
