<template>
  <div
    class="wrapper class_for_side_tooltips"
    :class="{ 'nav-open': $sidebar.showSidebar }"
  >
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <!-- Consider it as Profile PDF  -->
        <div class="wrapper_for_side_items">
          <sidebar-item
            v-if="hasPermission('access_dashboard')"
            :link="{
              name: `${
                localization('Admin Dashboard') != false
                  ? localization('Admin Dashboard')
                  : 'Admin Dashboard'
              }`,
              icon: 'nc-icon nc-chart-pie-35',
              path: '/overview'
            }"
          ></sidebar-item>
        </div>

        <!-- Consider it as Profile PDF  -->

        <!--//// Start User Dashboard Items In Medium And Large Screen // PROFILE  -->
        <div v-if="auth()" class="finally_handler_sidebar">
          <div @click="testHello" class="switch_between_arrows">
            <div class="pp_one">
              <i class="el-icon-user for_tt_icon"></i>
              <span class="for_tt_name">{{
                localization("My Profile") != false
                  ? localization("My Profile")
                  : "My Profile"
              }}</span>
            </div>
            <div class="pp_two">
              <i
                class="el-icon-caret-bottom handel_top_bottom"
                v-if="switchCollapse == false"
              ></i>
              <i
                class="el-icon-caret-top handel_top_bottom"
                v-if="switchCollapse == true"
              ></i>
            </div>
          </div>
          <div v-if="switchCollapse" class="switchCollapse_items">
            <!-- Start LCI  -->
            <el-tooltip
              class="item"
              :content="`${localization('lci tool tip')}`"
              placement="right-start"
              effect="dark"
            >
              <div class="handle_sidebar_tooltips ">
                <router-link
                  v-if="hasPermission('access_lci') || hasPermission('guest')"
                  class="just_style_if_sidebar"
                  to="/edit-questionnaire"
                  >{{
                    localization("LCI") != false ? localization("LCI") : "LCI"
                  }}</router-link
                >
              </div>
            </el-tooltip>

            <!-- Start Personal Learning Profile  -->
            <el-tooltip
              class="item"
              :content="`${localization('plp tool tip')}`"
              placement="right-start"
              effect="dark"
            >
              <div class="handle_sidebar_tooltips ">
                <router-link to="/profile" class="just_style_if_sidebar">{{
                  localization("Personal Learning Profile") != false
                    ? localization("Personal Learning Profile")
                    : "Personal Learning Profile"
                }}</router-link>
              </div>
            </el-tooltip>

            <!-- Edit Account -->
            <el-tooltip
              class="item"
              :content="`${localization('edit account tool tip')}`"
              placement="right-start"
            >
              <div class="handle_sidebar_tooltips ">
                <router-link to="/edit-user" class="just_style_if_sidebar">{{
                  localization("Edit Account") != false
                    ? localization("Edit Account")
                    : "Edit Account"
                }}</router-link>
              </div>
            </el-tooltip>
          </div>
        </div>
        <!--//// End User Dashboard Items // PROFILE  -->

        <div
          class="wrapper_for_side_items"
          v-if="
            userScore.confluence != null &&
              userScore.precision != null &&
              userScore.sequence != null &&
              userScore.technical_reasoning != null
          "
        >
          <sidebar-item
            :link="{
              name: `${
                localization('My Dashboard') != false
                  ? localization('My Dashboard')
                  : 'My Dashboard'
              }`,
              icon: 'nc-icon nc-chart-pie-35',
              path: '/my-dashboard'
            }"
          ></sidebar-item>
        </div>
        <!--//? Start User Dashboard Items In Mobile Screen // PROFILE -->
        <sidebar-item
          class="just_display_if_mobile"
          v-if="auth()"
          :link="{
            name: `${
              localization('My Profile') != false
                ? localization('My Profile')
                : 'My Profile'
            }`,
            icon: 'el-icon-user'
          }"
        >
          <!-- Start LCI  -->
          <sidebar-item
            v-if="hasPermission('access_lci') || hasPermission('guest')"
            :link="{
              name: `${
                localization('LCI') != false ? localization('LCI') : 'LCI'
              }`,
              path: '/edit-questionnaire'
            }"
          ></sidebar-item>
          <!-- Start Personal Learning Profile  -->
          <sidebar-item
            :link="{
              name: `${
                localization('Personal Learning Profile') != false
                  ? localization('Personal Learning Profile')
                  : 'Personal Learning Profile'
              }`,
              path: '/profile'
            }"
          ></sidebar-item>

          <sidebar-item
            :link="{
              name: `${
                localization('Edit Account') != false
                  ? localization('Edit Account')
                  : 'Edit Account'
              }`,
              path: '/edit-user'
            }"
          ></sidebar-item>
        </sidebar-item>
        <!--//? End User Dashboard Items In Mobile Screen // PROFILE -->

        <!--//todo: Start Use Patterns Dropdown  -->
        <!-- [ Start In Medium And Large Screen ] -->
        <div
          class="finally_handler_sidebar"
          :class="{ 'd-none': hasPermission('guest') }"
        >
          <div @click="testHello2" class="switch_between_arrows">
            <div class="pp_one">
              <i class="nc-icon nc-ruler-pencil for_tt_icon"></i>
              <span class="for_tt_name">{{
                localization("Use Your Patterns") != false
                  ? localization("Use Your Patterns")
                  : "Use Your Patterns"
              }}</span>
            </div>
            <div class="pp_two">
              <i
                class="el-icon-caret-bottom handel_top_bottom"
                v-if="switchCollapse2 == false"
              ></i>
              <i
                class="el-icon-caret-top handel_top_bottom"
                v-if="switchCollapse2 == true"
              ></i>
            </div>
          </div>
          <div v-if="switchCollapse2" class="switchCollapse_items">
            <!-- Start Tasks  -->
            <el-tooltip
              class="item"
              :content="`${localization('use my pattern tool tip')}`"
              placement="right-start"
              effect="dark"
            >
              <div class="handle_sidebar_tooltips ">
                <router-link class="just_style_if_sidebar" to="/tasks">{{
                  localization("In School") != false
                    ? localization("In School")
                    : "In School"
                }}</router-link>
              </div>
            </el-tooltip>
            <!-- Start At Work  -->
            <el-tooltip
              class="item"
              :content="`${localization('use my pattern at work tool tip')}`"
              placement="right-start"
              effect="dark"
            >
              <div class="handle_sidebar_tooltips ">
                <router-link class="just_style_if_sidebar" to="/at-work">{{
                  localization("At Work") != false
                    ? localization("At Work")
                    : "At Work"
                }}</router-link>
              </div>
            </el-tooltip>

            <!-- Start Career Match   -->
            <el-tooltip
              class="item"
              :content="
                `${localization('use my pattern career match tool tip')}`
              "
              placement="right-start"
              effect="dark"
            >
              <div class="handle_sidebar_tooltips ">
                <router-link
                  class="just_style_if_sidebar"
                  to="/career-match-questions"
                  >{{
                    localization("Career Match ") != false
                      ? localization("Career Match")
                      : "Career Match"
                  }}</router-link
                >
              </div>
            </el-tooltip>
            <!-- Career Match Questions -->
          </div>
        </div>
        <!-- [ End In Medium And Large Screen ] -->

        <!-- [In Mobile Screen] -->
        <sidebar-item
          class="just_display_if_mobile"
          :link="{
            name: `${
              localization('Use Your Patterns') != false
                ? localization('Use Your Patterns')
                : 'Use Your Patterns'
            }`,
            icon: 'nc-icon nc-ruler-pencil'
          }"
          :class="{ 'd-none': hasPermission('guest') }"
        >
          <!-- Start In School  -->
          <sidebar-item
            :link="{
              name: `${
                localization('In School') != false
                  ? localization('In School')
                  : 'In School'
              }`,
              path: '/tasks'
            }"
          ></sidebar-item>
          <!-- Start At Work  -->
          <sidebar-item
            :link="{
              name: `${
                localization('At Work') != false
                  ? localization('At Work')
                  : 'At Work'
              }`,
              path: '/at-work'
            }"
          ></sidebar-item>
          <!-- Start  Career Match  -->
          <sidebar-item
            :link="{
              name: `${
                localization('Career Match') != false
                  ? localization('Career Match')
                  : 'Career Match'
              }`,
              path: '/career-match-questions'
            }"
          ></sidebar-item>
        </sidebar-item>
        <!--//todo: End Use Patterns Dropdown [In Mobile Screen]-->

        <!-- Start Teams -->
        <el-tooltip
          class="item just_display_if_not_mobile"
          :content="`${localization('teams tool tip')}`"
          placement="right-start"
          :class="{ 'd-none': hasPermission('guest') }"
        >
          <sidebar-item
            :link="{
              name: `${
                localization('Teams') != false ? localization('Teams') : 'Teams'
              }`,
              icon: 'el-icon-collection',
              path: '/teams'
            }"
          ></sidebar-item>
        </el-tooltip>
        <div
          class="just_display_if_this_mobile"
          :class="{ 'd-none': hasPermission('guest') }"
        >
          <sidebar-item
            :link="{
              name: `${
                localization('Teams') != false ? localization('Teams') : 'Teams'
              }`,
              icon: 'el-icon-collection',
              path: '/teams'
            }"
          ></sidebar-item>
        </div>
        <!-- End Teams -->
        <!-- Start Contacts -->
        <el-tooltip
          class="item just_display_if_not_mobile"
          :content="`${localization('contacts tool tip')}`"
          placement="right-start"
          :class="{ 'd-none': hasPermission('guest') }"
        >
          <sidebar-item
            :link="{
              name: `${
                localization('Contacts') != false
                  ? localization('Contacts')
                  : 'Contacts'
              }`,
              icon: 'nc-icon nc-credit-card',
              path: '/contacts'
            }"
          ></sidebar-item>
        </el-tooltip>
        <div
          class="just_display_if_this_mobile"
          :class="{ 'd-none': hasPermission('guest') }"
        >
          <sidebar-item
            :link="{
              name: `${
                localization('Contacts') != false
                  ? localization('Contacts')
                  : 'Contacts'
              }`,
              icon: 'nc-icon nc-credit-card',
              path: '/contacts'
            }"
          ></sidebar-item>
        </div>
        <!-- End Contacts -->

        <!-- Start Groups -->
        <el-tooltip
          class="item just_display_if_not_mobile"
          :content="`${localization('groups tool tip')}`"
          placement="right-start"
          :class="{ 'd-none': hasPermission('guest') }"
        >
          <sidebar-item
            :link="{
              name: `${
                localization('Groups') != false
                  ? localization('Groups')
                  : 'Groups'
              }`,
              icon: 'nc-icon nc-paper-2',
              path: '/groups'
            }"
          ></sidebar-item>
        </el-tooltip>
        <div
          class="just_display_if_this_mobile"
          :class="{ 'd-none': hasPermission('guest') }"
        >
          <sidebar-item
            :link="{
              name: `${
                localization('Groups') != false
                  ? localization('Groups')
                  : 'Groups'
              }`,
              icon: 'nc-icon nc-paper-2',
              path: '/groups'
            }"
          ></sidebar-item>
        </div>
        <!-- End Groups -->

        <sidebar-item
          v-if="hasPermission('access_users')"
          :link="{
            name: `${
              localization('Users') != false ? localization('Users') : 'Users'
            }`,
            icon: 'fa fa-users',
            path: '/users'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="hasPermission('access_institutions')"
          :link="{
            name: `${
              localization('Institutions') != false
                ? localization('Institutions')
                : 'Institutions'
            }`,
            icon: 'nc-icon nc-bank',
            path: '/institution'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="
            hasPermission('access_topics') &&
              hasPermission('access_strategies') &&
              hasPermission('access_words') &&
              hasPermission('access_questions') &&
              hasPermission('access_cms') &&
              hasPermission('access_roles') &&
              hasPermission('access_logs')
          "
          :link="{
            name: `${
              localization('Management') != false
                ? localization('Management')
                : 'Management'
            }`,
            icon: 'nc-icon nc-settings-90',
            path: '/management'
          }"
        ></sidebar-item>

        <sidebar-item
          v-if="hasPermission('manage_institutions')"
          :link="{
            name: `User Categories`,
            icon: 'nc-icon nc-settings-90',
            path: '/user-categories'
          }"
        ></sidebar-item>

        <!-- Start how will lci help you -->
        <el-tooltip
          class="item just_display_if_not_mobile"
          :content="`${localization('how will the lci help tool tip')}`"
          placement="right-start"
          :class="{ 'd-none': hasPermission('guest') }"
        >
          <sidebar-item
            :link="{
              name: `${
                localization('how will lci help you') != false
                  ? localization('how will lci help you')
                  : 'how will the lci help you'
              }`,
              icon: 'el-icon-question',
              path: '/lci-help-you'
            }"
          ></sidebar-item>
        </el-tooltip>
        <div
          class="just_display_if_this_mobile"
          :class="{ 'd-none': hasPermission('guest') }"
        >
          <sidebar-item
            :link="{
              name: `${
                localization('how will lci help you') != false
                  ? localization('how will lci help you')
                  : 'how will the lci help you'
              }`,
              icon: 'el-icon-question',
              path: '/lci-help-you'
            }"
          ></sidebar-item>
        </div>
        <!-- End how will lci help you -->

        <!-- Start Videos -->
        <el-tooltip
          class="item just_display_if_not_mobile"
          :content="`${localization('videos tool tip')}`"
          placement="right-start"
        >
          <sidebar-item
            :link="{
              name: `${
                localization('Videos') != false
                  ? localization('Videos')
                  : 'Videos'
              }`,
              icon: 'el-icon-video-play',
              path: '/videos'
            }"
          ></sidebar-item>
        </el-tooltip>
        <div class="just_display_if_this_mobile">
          <sidebar-item
            :link="{
              name: `${
                localization('Videos') != false
                  ? localization('Videos')
                  : 'Videos'
              }`,
              icon: 'el-icon-video-play',
              path: '/videos'
            }"
          ></sidebar-item>
        </div>
        <!-- End Videos -->
        <sidebar-item
          v-if="auth().family_type == 1 || auth().family_type == 2"
          :link="{
            name: `${
              localization('Family Members') != false
                ? localization('Family Members')
                : 'Family Members'
            }`,
            icon: 'nc-icon nc-bank',
            path: '/family-members'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="hasPermission('access_words')"
          :link="{
            name: `${
              localization('Families') != false
                ? localization('Families')
                : 'Families'
            }`,
            icon: 'nc-icon nc-bank',
            path: '/families'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: `${
              localization('Logout') != false
                ? localization('Logout')
                : 'Logout'
            }`,
            icon: 'fa fa-sign-out',
            path: '/logout'
          }"
        ></sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel" v-loading="$store.getters['roles/rolesLoad']">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
// import PerfectScrollbar from 'perfect-scrollbar';
// import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  data() {
    return {
      navbarObj: {},
      switchCollapse: false,
      switchCollapse2: false
    };
  },
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
    // UserMenu
  },
  watch: {
    $route(to, from) {
      if (this.auth()) {
        this.$store
          .dispatch("roles/getAuthUserRolesPermissions", this.auth().id)
          .then(res => {
            // console.log(this.authUserPermission);
            if (!this.hasPermission("guest")) {
              // console.log("i;m guest");
              if (
                this.$store.getters["roles/authUser"].sequence == null ||
                this.$store.getters["roles/authUser"].precision == null ||
                this.$store.getters["roles/authUser"].technical_reasoning ==
                  null ||
                this.$store.getters["roles/authUser"].confluence == null
              ) {
                // check to & from type
                // user need to be redirected to the before page if no LCI score
                if (from.path !== "/edit-questionnaire") {
                  // if (from.path !== "/before") {
                  // If no score redirect to the Before page
                  this.$router.push(`/edit-questionnaire`);
                  // this.$router.push(`/before`);
                } else {
                  // if user is in the Before page, then allow redirect to the LCI page
                  this.$router.push(`/edit-questionnaire`);
                }
              }
            }
          });
      }
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    initScrollbar() {
      // let docClasses = document.body.classList;
      // let isWindows = navigator.platform.startsWith('Win');
      // if (isWindows) {
      //   // if we are on windows OS we activate the perfectScrollbar function
      //   initScrollbar('main-panel');
      //
      //   docClasses.add('perfect-scrollbar-on');
      // } else {
      //   docClasses.add('perfect-scrollbar-off');
      // }
    },
    testHello() {
      this.switchCollapse = !this.switchCollapse;
    },
    testHello2() {
      this.switchCollapse2 = !this.switchCollapse2;
    }
  },
  computed: {
    id() {
      // return this.auth().id;
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    authUserPermission() {
      return this.$store.getters["roles/authUserRolesPermissionsName"];
    }
  },
  mounted() {
    // this.initScrollbar()
    if (this.auth()) {
      this.$store
        .dispatch("roles/getAuthUserRolesPermissions", this.auth().id)
        .then(_ => {
          // console.log("hello");
          // console.log(this.authUserPermission);
          if (
            this.$store.getters["roles/authUser"].sequence == null ||
            this.$store.getters["roles/authUser"].precision == null ||
            this.$store.getters["roles/authUser"].technical_reasoning == null ||
            this.$store.getters["roles/authUser"].confluence == null
          ) {
            this.$router.push(`/before`);
          }
        });
      this.$store.dispatch("user/user", this.id);
    }
  }
};
</script>

<style>
.swal2-container.swal2-bottom,
.swal2-container.swal2-center,
.swal2-container.swal2-top {
  grid-template-columns: auto minmax(0, 1fr) auto;
  z-index: 100000;
}
</style>

<style scoped lang="scss">
.main-panel {
  background: rgba(203, 203, 210, 0.15) !important;
  height: auto !important;
}
.just_display_if_mobile {
  display: none;
  @media (min-width: 320px) and (max-width: 700px) {
    display: block;
  }
}

.just_display_if_this_mobile {
  display: none;
  @media (min-width: 320px) and (max-width: 700px) {
    display: block;
  }
}
.finally_handler_sidebar {
  @media (min-width: 320px) and (max-width: 700px) {
    display: none;
  }
  .switch_between_arrows {
    display: flex;
    justify-content: space-between;
    padding: 10px 22px;
    margin: 5px 0 0 0;
    opacity: 0.86;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background-color: #00563f;
      color: #fff;
      opacity: 1;
    }
    .pp_one {
      display: flex;
      .for_tt_name {
        margin-left: 15px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        opacity: 0.86;
      }
      .for_tt_icon {
        font-size: 17px;
      }
    }
    .pp_two {
      .handel_top_bottom {
        font-size: 12px;
      }
    }
  }
  .handle_sidebar_tooltips {
    // padding: 5px 0;
    // margin: 5px 0 0 0;
    padding: 0 15px;
    .just_style_if_sidebar {
      display: block;
      color: #fff;
      // margin: 5px 15px 0px 10px;
      margin: 5px 0 0 0;
      opacity: 0.86;
      border-radius: 4px;
      text-transform: uppercase;
      line-height: 30px;
      font-size: 12px;
      font-weight: 400;
      padding: 5px 0 5px 25px;
      white-space: nowrap;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      &:hover {
        background-color: #00563f !important;
        opacity: 1;
      }
      &.router-link-exact-active {
        background-color: #00563f !important;
        opacity: 1;
      }
    }
  }
  .switchCollapse_items {
    transition: 0.5s;
  }
}
</style>

<style lang="scss">
.class_for_side_tooltips {
}
.el-tooltip__popper.is-dark {
  background-color: #00563f !important;
  color: #fff;
  max-width: 300px;

  &::after {
    border-right-color: #00563f;
  }

  // }
}
.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-right-color: #00563f;
}
.just_display_if_not_mobile {
  display: none;
  @media (min-width: 700px) {
    display: block;
  }
}
</style>
