import Resource from './resource';
import request from "../utils/request";

class Dashboard extends Resource {
  constructor() {
    super('dashboard');
  }




  users_type() {
    return request({
      url: `/dashboard/users_type`,
      method: 'get',
    })
  }

  logs_count() {
    return request({
      url: `/dashboard/logs_count`,
      method: 'get',
    })
  }
   task_count() {
    return request({
      url: `/dashboard/task_count`,
      method: 'get',
    })
  }
   org_count() {
    return request({
      url: `/dashboard/org_count`,
      method: 'get',
    })
  }
  activation_count() {
    return request({
      url: `/dashboard/users/activation/count`,
      method: 'get',
    })
  }
  users_statistics() {
    return request({
      url: `/users/statistics`,
      method: 'get',
    })
  }
}

export { Dashboard as default };
