import Resource from "./resource";
import request from "../utils/request";

class Organizations extends Resource {
  constructor() {
    super("organizations");
  }

  get(id) {
    return request({
      url: `/organizations/show/${id}`,
      method: "get"
    });
  }
  getOrgs() {
    return request({
      url: `/orgs`,
      method: "post"
    });
  }
  save(resource, id) {
    return request({
      url: `/organizations/save/${id}`,
      method: "put",
      data: resource
    });
  }

  savenew(resource) {
    return request({
      url: `/organizations/save`,
      method: "put",
      data: resource
    });
  }

  // addOrganizationPayment(resource, id) {
  //   return request({
  //     url: `/organizations/add/${id}`,
  //     method: "POST",
  //     data: resource
  //   });
  // }

  Import(resource) {
    return request({
      url: `/organizations/import`,
      method: "post",
      "Content-Type": "multipart/form-data",
      data: resource
    });
  }

  Export(query) {
    return request({
      url: `/organizations/export`,
      method: "post",
      responseType: "blob",
      data: query
    });
  }
  // add users from institution to another institution
  change_organization(payload) {
    return request({
      url: `/users/change/organization`,
      method: "post",
      data: payload
    });
  }
  get_all_organization() {
    return request({
      url: `/orgs`,
      method: "get"
    });
  }
}

export { Organizations as default };
